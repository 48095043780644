<template>
    <v-container fluid style="height: 100%" class="d-flex align-center justify-center">
      <div v-if="!allowedAccess">
        <v-row class="pt-3 text-center">
          <v-col cols="12" class="mb-10">
            <i
              class="fal fa-unlink grey--text ma-4 text-center"
              style="font-size: 70px"
            ></i>
            <p class="text-h6 grey--text">Oh no, parece que el link que te asignarón ya expiró.</p>
            <p class="text-subtitle-2 grey--text">Comunicate con el encargado para que te genere uno nuevo.</p>
          </v-col>
          <v-col cols="12" class="d-flex justify-center mt-10">
            <v-img max-width="150px" src="../../assets/logo.png"></v-img>
          </v-col>
        </v-row>
      </div>
      <div v-if="allowedAccess">
        <div class="text-center" v-if="habeasDataDialog == true">
          <v-dialog v-model="habeasDataDialog" fullscreen>
            <div class="d-flex justify-end" style="background-color: #31394C">
              <v-btn class="mt-1 mr-1" icon small @click="habeasDataDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <habeas-data :enterprise="companyID" :habeasType="empresaHabeasID" :habeasStatus="habeasData" :isVisitor="isVisitor"></habeas-data>
          </v-dialog>
        </div>
        <v-overlay opacity="1" :value="loadingSelfieBtn">
        <v-card color="primary" dark>
          <v-card-text>
            Validando selfie, por favor espere...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
        </v-overlay>
        <v-overlay opacity="1" :value="loadingFrontDocBtn">
          <v-card color="primary" dark>
            <v-card-text>
              Validando documento, por favor espere...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-overlay>
        <v-overlay opacity="1" :value="loadingBackDocBtn">
          <v-card color="primary" dark>
            <v-card-text>
              Validando documento, por favor espere...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-overlay>
      
        <v-card width="100%">
    <v-card-title class="d-flex justify-center">
      <!-- <span class="text-h6">Registro Vanguard Vision</span> -->
      <v-img max-width="170px" src="../../assets/logo.png"></v-img>
    </v-card-title>
    <v-card-text>
        <!-- INFORMATIVE PANEL -->
        <v-row class="pt-3" v-if="infoPanel">
          <v-col cols="12" class="d-flex justify-center">
            <div>
              <p class="h5-text mb-1">Bienvenido(a) al modulo de registro OneID de <strong>Vanguard Vision.</strong></p>
              <!-- <p class="h5-text mb-1">Al hacer click en el botón de <strong>COMENCEMOS</strong>, usted esta confirmando y aceptando la</p>
              <p v-if="companyID !== 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17'" class="h5-text mb-1">politica de tratamiento de datos</p> -->
              <p v-if="companyID === 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17'" class="h5-text mb-1"><strong>"AUTORIZACION TRATAMIENTO DE DATOS PERSONALES<!--  DE EMPLEADOS QUE INGRESAN A LAS SEDES DE LA ORGANIZACIÓN SOLLA S.A. -->"</strong></p>
              <v-btn v-if="companyID === 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17' || companyID === 'bbc39d33-128f-474a-bea7-81e2edabdc5c'" class="mb-2" color="secondary" x-small @click="iReadPolicy = true ; habeasDataDialog = true">Tratamiento de Datos Personales</v-btn>
              <!-- <p class="h5-text mb-1">Al hacer click en el botón de comencemos, usted esta confirmando y aceptando los <strong style="cursor: pointer">Terminos y Condiciones</strong> </p> -->
              <!-- <p class="h5-text mb-1">y usted acepta que ha leido la <strong style="cursor: pointer">Politica de Privacidad</strong> </p> -->
              <p class="h6-text mb-3">Antes de iniciar con el proceso por favor tener en cuenta lo siguiente para obtener un registro exitoso</p>
              <p><strong>-</strong> Limpiar el lente de la cámara.</p>
              <p><strong>-</strong> Aceptar el permiso de la cámara cuando esta lo pregunte.</p>
              <p><strong>-</strong> Evitar fuentes directas de luz como bombillos o lámparas, preferiblemente hacerlo en un lugar con luz natural.</p>
              <p><strong>-</strong> Tener a la mano el documento de identidad.</p>
            </div>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="text-center">
            <v-btn
              v-if="companyID === 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17' || companyID === 'bbc39d33-128f-474a-bea7-81e2edabdc5c'"
              rounded
              color="primary"
              class="px-4 mx-2 primaryBtn"
              :disabled="!habeasData"
              @click="infoPanel = false ; firstPanel = true"
            >
              COMENCEMOS
            </v-btn>
            <v-btn
              v-else
              rounded
              color="primary"
              class="px-4 mx-2 primaryBtn"
              @click="infoPanel = false ; firstPanel = true"
            >
              COMENCEMOS
            </v-btn>
          </v-col>
        </v-row>
        <!-- FIRST PANEL -->
        <v-row class="pt-3" v-if="firstPanel">
          <div v-if="selfiePhotoModule1 && !selfiePhotoModule2">
            <v-col cols="12" class="d-flex justify-center">
              <div class="text-center">
                <p class="text-h5 pb-2">Toma de Selfie</p>
                <p class="text-body-2">Retire lentes, sombrero, gorra o mascarilla antes de hacer la toma de la selfie.</p>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <take-photo :typeUse="'selfie'" :imageName="'selfie_' + userUUID" v-on:photo-taken-selfie="validateSelfieNew"></take-photo>
            </v-col>
            <v-col cols="12" class="d-flex justify-center ma-0">
              <v-btn
                x-small
                color="secondary"
                @click="selfiePhotoModule1 = !selfiePhotoModule1 ; selfiePhotoModule2 = !selfiePhotoModule2"
              >
                Cambiar Modo
              </v-btn>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
              <v-btn
                rounded
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="checkSelfie"
                :loading="loadingSelfieBtn"
                :disabled="!s3SelfieKey"
              >
                VALIDAR SELFIE
              </v-btn>
            </v-col>
          </div>
          <div v-if="selfiePhotoModule2 && !selfiePhotoModule1">
            <v-col cols="12" class="d-flex justify-center">
              <div class="text-center">
                <p class="text-h5 pb-2">Toma de Selfie</p>
                <p class="text-body-2">Retire lentes, sombrero, gorra o mascarilla antes de hacer la toma de la selfie.</p>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-icon v-if="selfieImg == null" style="font-size: 120px;" x-large color="grey" dark> mdi-account-box</v-icon>
              <v-img
                v-if="selfieImg != null"
                :src="selfieImg"
                width="250px"
                height="200px"
                class="white--text align-center"
                
                aspect-ratio="2"
                contain
              >
              </v-img>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                color="success"
                class="white--text"
                small
                @click="clickSelfieInput"
              >
                SUBIR SELFIE
              </v-btn>
              <input
                type="file"
                @change="readSelfieFile"
                style="display: none"
                ref="selfieInput"
                accept="image/*"
              />
            </v-col>
            <v-col cols="12" class="d-flex justify-center ma-0">
              <v-btn
                x-small
                color="secondary"
                @click="selfiePhotoModule1 = !selfiePhotoModule1 ; selfiePhotoModule2 = !selfiePhotoModule2"
              >
                Cambiar Modo
              </v-btn>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
              <v-btn
                rounded
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="validateSelfie"
                :loading="loadingSelfieBtn"
                :disabled="!selfieImg"
              >
                VALIDAR SELFIE
              </v-btn>
            </v-col>
          </div>
        </v-row>
        <!-- PERSON ALREADY EXIST PANEL -->
        <v-row class="pt-3" v-if="registered_person_panel">
          <!-- <v-col cols="12" class="d-flex justify-center">
            <v-img
              v-if="selfieImg != null"
              :src="selfieImg"
              width="250px"
              height="200px"
              class="white--text align-center"
              
              aspect-ratio="2"
              contain
            >
            </v-img>
          </v-col> -->
          <v-col cols="12" class="d-flex justify-center">
            <p>Usted ya se encuentra registrado(a) en la plataforma.</p>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="text-center">
            <v-btn
              rounded
              color="primary"
              class="px-4 mx-2 primaryBtn"
              @click="clearRegistry"
            >
              VOLVER A INICIO
            </v-btn>
          </v-col>
        </v-row>
        <!-- SECOND PANEL -->
        <v-row class="pt-3" v-if="secondPanel && registry.tipoDocumento == ''">
                <v-col cols="12">
                    <v-select
                    v-model="registry.tipoDocumento"
                    :items="documentTypes"
                    item-text="Title"
                    item-value="Type"
                    :rules="[(v) => !!v || 'El campo es obligatorio.']"
                    label="Seleccione el tipo de documento con el que hará su registro"
                    outlined
                    dense
                    dark
                    color="secondary"
                    required
                    ></v-select>
                </v-col>
        </v-row>
        <!-- SECOND PANEL IMG DOCUMENT -->
        <v-row class="pt-3" v-if="secondPanel && registry.tipoDocumento != '' && registry.tipoDocumento != 'PASS' && registry.tipoDocumento != 'PPT'">
          <div v-if="frontDocumentPhotoModule1 && !frontDocumentPhotoModule2">
            <v-col cols="12" class="d-flex justify-center">
              <div class="text-center">
                <p class="text-h5 pb-2">Documento de Identidad Lado Frontal</p>
                <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
              </div>
            </v-col>
            <v-col v-if="secondPanel && registry.tipoDocumento != ''" cols="12" class="d-flex justify-center">
              <take-photo :typeUse="'frontDoc'" :imageName="'front-doc_' + userUUID" v-on:photo-taken-front="validateFrontPicNew"></take-photo>
            </v-col>
            <v-col cols="12" class="d-flex justify-center ma-0">
              <v-btn
                small
                color="secondary"
                @click="frontDocumentPhotoModule1 = !frontDocumentPhotoModule1 ; frontDocumentPhotoModule2 = !frontDocumentPhotoModule2"
              >
                Cambiar Modo
              </v-btn>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
                <!-- <v-btn
                rounded
                color="grey"
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
                >
                ATRAS
                </v-btn> -->
                <v-btn
                rounded
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="checkFrontDoc"
                :loading="loadingFrontDocBtn"
                :disabled="!s3FrontDocKey"
                >
                VALIDAR FOTO FRONTAL
                </v-btn>
            </v-col>
          </div>
          <div v-if="frontDocumentPhotoModule2 && !frontDocumentPhotoModule1">
            <v-col cols="12" class="d-flex justify-center">
              <div class="text-center">
                <p class="text-h5 pb-2">Documento de Identidad Lado Frontal</p>
                <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-img
                v-if="frontDocImg == null"
                src="../../assets/doc2-white.png"
                width="300px"
                height="150px"
                class="white--text"
                aspect-ratio="2"
                contain
                >
              </v-img>
              <v-img
                v-if="frontDocImg != null"
                :src="frontDocImg"
                width="250px"
                height="200px"
                class="white--text align-center"
                
                aspect-ratio="2"
                contain
                >
              </v-img>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                color="success"
                class="white--text"
                small
                @click="clickFrontDocInput"
                >
                SUBIR DOCUMENTO LADO FRONTAL
              </v-btn>
              <input
                type="file"
                @change="readFrontDocFile"
                style="display: none"
                ref="frontDocInput"
                accept="image/*"
              />
            </v-col>
            <v-col cols="12" class="d-flex justify-center ma-0">
              <v-btn
                small
                color="secondary"
                @click="frontDocumentPhotoModule1 = !frontDocumentPhotoModule1 ; frontDocumentPhotoModule2 = !frontDocumentPhotoModule2"
              >
                Cambiar Modo
              </v-btn>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
              <!-- <v-btn
                rounded
                color="grey"
                large
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
              >
                ATRAS
              </v-btn> -->
              <v-btn
                rounded
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="validateFrontDocumentImg"
                :loading="loadingFrontDocBtn"
                :disabled="!frontDocImg"
              >
                VALIDAR FOTO FRONTAL
              </v-btn>
            </v-col>
          </div>
        </v-row>

        <!-- SECOND PANEL IMG DOCUMENT PASSPORT -->
        <v-row class="pt-3" v-if="secondPanel && registry.tipoDocumento != '' && registry.tipoDocumento == 'PASS'">
          <div v-if="frontDocumentPhotoModule1 && !frontDocumentPhotoModule2">
            <v-col cols="12" class="d-flex justify-center">
              <div class="text-center">
                <p class="text-h5 pb-2">Documento de Identidad en el Exterior - Pasaporte</p>
                <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
              </div>
            </v-col>
            <v-col v-if="secondPanel && registry.tipoDocumento != ''" cols="12" class="d-flex justify-center">
              <take-photo :typeUse="'frontDoc'" :imageName="'front-doc_' + userUUID" v-on:photo-taken-front="validateFrontPicNew"></take-photo>
            </v-col>
            <v-col cols="12" class="d-flex justify-center ma-0">
              <v-btn
                small
                color="secondary"
                @click="frontDocumentPhotoModule1 = !frontDocumentPhotoModule1 ; frontDocumentPhotoModule2 = !frontDocumentPhotoModule2"
              >
                Cambiar Modo
              </v-btn>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
                <!-- <v-btn
                rounded
                color="grey"
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
                >
                ATRAS
                </v-btn> -->
                <v-btn
                rounded
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="checkFrontDoc"
                :loading="loadingFrontDocBtn"
                :disabled="!s3FrontDocKey"
                >
                VALIDAR DOCUMENTO
                </v-btn>
            </v-col>
          </div>
          <div v-if="frontDocumentPhotoModule2 && !frontDocumentPhotoModule1">
            <v-col cols="12" class="d-flex justify-center">
              <div class="text-center">
                <p class="text-h5 pb-2">Documento de Identidad en el Exterior - Pasaporte</p>
                <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-img
                v-if="frontDocImg == null"
                src="../../assets/doc2-white.png"
                width="300px"
                height="150px"
                class="white--text"
                aspect-ratio="2"
                contain
                >
              </v-img>
              <v-img
                v-if="frontDocImg != null"
                :src="frontDocImg"
                width="250px"
                height="200px"
                class="white--text align-center"
                
                aspect-ratio="2"
                contain
                >
              </v-img>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                color="success"
                class="white--text"
                small
                @click="clickFrontDocInput"
                >
                SUBIR DOCUMENTO
              </v-btn>
              <input
                type="file"
                @change="readFrontDocFile"
                style="display: none"
                ref="frontDocInput"
                accept="image/*"
              />
            </v-col>
            <v-col cols="12" class="d-flex justify-center ma-0">
              <v-btn
                small
                color="secondary"
                @click="frontDocumentPhotoModule1 = !frontDocumentPhotoModule1 ; frontDocumentPhotoModule2 = !frontDocumentPhotoModule2"
              >
                Cambiar Modo
              </v-btn>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
              <!-- <v-btn
                rounded
                color="grey"
                large
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
              >
                ATRAS
              </v-btn> -->
              <v-btn
                rounded
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="validateFrontDocumentImg"
                :loading="loadingFrontDocBtn"
                :disabled="!frontDocImg"
              >
                VALIDAR DOCUMENTO
              </v-btn>
            </v-col>
          </div>
        </v-row>

        <!-- SECOND PANEL IMG DOCUMENT PPT -->
        <v-row class="pt-3" v-if="secondPanel && registry.tipoDocumento != '' && registry.tipoDocumento == 'PPT'">
          <div v-if="frontDocumentPhotoModule1 && !frontDocumentPhotoModule2">
            <v-col cols="12" class="d-flex justify-center">
              <div class="text-center">
                <p class="text-h5 pb-2">Permiso por protección personal  - PPT</p>
                <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
              </div>
            </v-col>
            <v-col v-if="secondPanel && registry.tipoDocumento != ''" cols="12" class="d-flex justify-center">
              <take-photo :typeUse="'frontDoc'" :imageName="'front-doc_' + userUUID" v-on:photo-taken-front="validateFrontPicNew"></take-photo>
            </v-col>
            <v-col cols="12" class="d-flex justify-center ma-0">
              <v-btn
                small
                color="secondary"
                @click="frontDocumentPhotoModule1 = !frontDocumentPhotoModule1 ; frontDocumentPhotoModule2 = !frontDocumentPhotoModule2"
              >
                Cambiar Modo
              </v-btn>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
                <!-- <v-btn
                rounded
                color="grey"
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
                >
                ATRAS
                </v-btn> -->
                <v-btn
                rounded
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="checkFrontDoc"
                :loading="loadingFrontDocBtn"
                :disabled="!s3FrontDocKey"
                >
                VALIDAR DOCUMENTO
                </v-btn>
            </v-col>
          </div>
          <div v-if="frontDocumentPhotoModule2 && !frontDocumentPhotoModule1">
            <v-col cols="12" class="d-flex justify-center">
              <div class="text-center">
                <p class="text-h5 pb-2">Permiso por protección personal  - PPT</p>
                <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-img
                v-if="frontDocImg == null"
                src="../../assets/doc2-white.png"
                width="300px"
                height="150px"
                class="white--text"
                aspect-ratio="2"
                contain
                >
              </v-img>
              <v-img
                v-if="frontDocImg != null"
                :src="frontDocImg"
                width="250px"
                height="200px"
                class="white--text align-center"
                
                aspect-ratio="2"
                contain
                >
              </v-img>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                color="success"
                class="white--text"
                small
                @click="clickFrontDocInput"
                >
                SUBIR DOCUMENTO
              </v-btn>
              <input
                type="file"
                @change="readFrontDocFile"
                style="display: none"
                ref="frontDocInput"
                accept="image/*"
              />
            </v-col>
            <v-col cols="12" class="d-flex justify-center ma-0">
              <v-btn
                small
                color="secondary"
                @click="frontDocumentPhotoModule1 = !frontDocumentPhotoModule1 ; frontDocumentPhotoModule2 = !frontDocumentPhotoModule2"
              >
                Cambiar Modo
              </v-btn>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
              <!-- <v-btn
                rounded
                color="grey"
                large
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
              >
                ATRAS
              </v-btn> -->
              <v-btn
                rounded
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="validateFrontDocumentImg"
                :loading="loadingFrontDocBtn"
                :disabled="!frontDocImg"
              >
                VALIDAR DOCUMENTO
              </v-btn>
            </v-col>
          </div>
        </v-row>

        <!-- INTERMEDIARY PANEL -->
        <v-row class="pt-3" v-if="intermediaryPanel">
          <v-col cols="12" class="text-center">
          </v-col>
        </v-row>
        <!-- THIRD PANEL -->
        <v-row class="pt-3" v-if="thirdPanel">
          <div v-if="backDocumentPhotoModule1 && !backDocumentPhotoModule2">
            <v-col cols="12" class="d-flex justify-center">
              <div class="text-center">
                <p class="text-h5 pb-2">Documento de Identidad Lado Posterior</p>
                <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
              </div>
            </v-col>
            <v-col v-if="thirdPanel" cols="12" class="d-flex justify-center">
              <take-photo :typeUse="'backDoc'" :imageName="'back-doc_' + userUUID" v-on:photo-taken-back="validateBackNew"></take-photo>
            </v-col>
            <v-col cols="12" class="d-flex justify-center ma-0">
              <v-btn
                small
                color="secondary"
                @click="backDocumentPhotoModule1 = !backDocumentPhotoModule1 ; backDocumentPhotoModule2 = !backDocumentPhotoModule2"
              >
                Cambiar Modo
              </v-btn>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
              <!-- <v-btn
                rounded
                color="grey"
                class="px-4 mx-2 primaryBtn"
                @click="thirdPanel = false ; secondPanel = true"
              >
                ATRAS
              </v-btn> -->
              <v-btn
                rounded
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="checkBackDoc"
                :loading="loadingBackDocBtn"
                :disabled="!s3BackDocKey"
              >
                VALIDAR POSTERIOR
              </v-btn>
            </v-col>
          </div>
          <div v-if="backDocumentPhotoModule2 && !backDocumentPhotoModule1">
            <v-col cols="12" class="d-flex justify-center">
              <div class="text-center">
                <p class="text-h5 pb-2">Documento de Identidad Lado Posterior</p>
                <p class="text-body-2">Acerque el documento y verifique por favor que el contenido de la foto se vea claramente.</p>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-img
                v-if="backDocImg == null"
                src="../../assets/doc-white.png"
                width="300px"
                height="150px"
                class="white--text"
                aspect-ratio="2"
                contain
              >
              </v-img>
              <v-img
                v-if="backDocImg != null"
                :src="backDocImg"
                width="250px"
                height="200px"
                class="white--text align-center"
                
                aspect-ratio="2"
                contain
              >
              </v-img>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn
                color="success"
                class="white--text"
                small
                @click="clickBackDocInput"
              >
              SUBIR DOCUMENTO LADO POSTERIOR
              </v-btn>
              <input
                type="file"
                @change="readBackDocFile"
                style="display: none"
                ref="backDocInput"
                accept="image/*"
              />
            </v-col>
            <v-col cols="12" class="d-flex justify-center ma-0">
              <v-btn
                small
                color="secondary"
                @click="backDocumentPhotoModule1 = !backDocumentPhotoModule1 ; backDocumentPhotoModule2 = !backDocumentPhotoModule2"
              >
                Cambiar Modo
              </v-btn>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
              <!-- <v-btn
                rounded
                color="grey"
                large
                class="px-4 mx-2 primaryBtn"
                @click="secondPanel = false ; firstPanel = true"
              >
                ATRAS
              </v-btn> -->
              <v-btn
                rounded
                color="primary"
                class="px-4 mx-2 primaryBtn"
                @click="validateBackDocumentImg"
                :loading="loadingBackDocBtn"
                :disabled="!backDocImg"
              >
                VALIDAR FOTO POSTERIOR
              </v-btn>
            </v-col>
          </div>          
        </v-row>
        <!-- FOURTH PANEL -->
        <v-form v-if="fourthPanel" ref="form" v-model="valid" lazy-validation>
        <v-row class="pt-3" v-if="fourthPanel">
            <v-col cols="12" md="12" class="py-1 px-3">
                <!-- <p>** En caso de que su documento no sea una Cédula de Ciudadanía, seleccione el tipo correcto.</p> -->
                <p v-if="registry.tipoDocumento == 'CC'" class="mb-2 grey--text">* Valide los datos que se extrajeron del documento y en caso de no ser correctos modifiquelos por favor.</p>
                <p class="mb-2 grey--text">* Todos los campos son obligatorios.</p>
                <p class="mb-2 grey--text">* Es importante que ingrese su numero celular y su correo de manera correcta.</p>
            </v-col>
            <!-- <v-col cols="12" md="4" class="py-1 px-2">
                <v-select
                v-model="registry.tipoDocumento"
                :items="documentTypes"
                item-text="Title"
                item-value="Type"
                :rules="[(v) => !!v || 'El campo es obligatorio.']"
                label="Tipo Documento"
                outlined
                dense
                dark
                color="secondary"
                required
                ></v-select>
            </v-col> -->
            <v-col cols="12" md="6" class="py-1 px-3">
                <v-text-field
                v-model="registry.id"
                :rules="registry.tipoDocumento == 'PASS' ? passRules : ccRules"
                label="Número Documento"
                outlined
                dense
                dark
                color="secondary"
                required
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-1 px-3">
                <v-menu
                v-model="datepicker1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="expeditionDatePicker"
                    label="Fecha de expedición"
                    prepend-inner-icon="mdi-calendar"
                    :rules="[(v) => !!v || 'El campo es obligatorio.']"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    dark
                    color="secondary"
                    required
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="expeditionDatePicker"
                    @input="datepicker1 = false"
                    locale="es-MX"
                ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="3" class="py-1 px-3">
                <v-text-field
                v-model="registry.firstname"
                :rules="namesRule"
                label="Primer Nombre"
                outlined
                dark
                dense
                color="secondary"
                required
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-1 px-3">
                <v-text-field
                v-model="registry.secondname"
                label="Segundo Nombre"
                :rules="secondNameRule"
                outlined
                dense
                dark
                color="secondary"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-1 px-3">
                <v-text-field
                v-model="registry.surname1"
                :rules="namesRule"
                label="Primer Apellido"
                outlined
                dense
                dark
                color="secondary"
                required
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-1 px-3">
                <v-text-field
                v-model="registry.surname2"
                label="Segundo Apellido"
                :rules="secondNameRule"
                outlined
                dense
                dark
                color="secondary"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-1 px-3">
                <v-text-field
                v-model="registry.phone"
                :rules="phoneRules"
                label="Celular"
                outlined
                dense
                dark
                color="secondary"
                required
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-1 px-3">
                <v-text-field
                v-model="registry.email"
                label="Correo Electronico"
                :rules="emailRules"
                outlined
                dense
                dark
                required
                color="secondary"
                ></v-text-field>
            </v-col>
            <!-- 

            <v-col cols="12" md="3" class="py-1 px-3">
                <v-select
                v-model="registry.sex"
                :items="genres"
                item-text="Title"
                item-value="Type"
                :rules="[(v) => !!v || 'El campo es obligatorio.']"
                label="Género"
                outlined
                dark
                color="secondary"
                required
                ></v-select>
            </v-col>
            <v-col cols="12" md="3" class="py-1 px-3">
                <v-select
                v-model="registry.blood"
                :items="bloodTypes"
                item-text="Title"
                :rules="[(v) => !!v || 'El campo es obligatorio.']"
                label="Tipo de Sangre"
                outlined
                dark
                color="secondary"
                required
                ></v-select>
            </v-col> -->
            <v-divider></v-divider>
            <v-col cols="12" class="text-center">
                <!-- <v-btn
                rounded
                color="grey"
                class="px-4 mx-2 mb-2 primaryBtn"
                @click="fourthPanel = false ; thirdPanel = true"
                >
                ATRAS
                </v-btn> -->
                <v-btn
                :disabled="!valid"
                rounded
                color="primary"
                class="px-4 mx-2 mb-2 primaryBtn"
                :loading="loadingRegistryCreationBtn"
                @click="createRegistry(registry)"
                >
                CREAR REGISTRO
                </v-btn>
            </v-col>
            </v-row>
        </v-form>
        <!-- FINAL VISITOR PANEL -->
        <v-row class="pt-3" v-if="successRegistrationVisitorPanel">
          <v-col cols="12" class="d-flex justify-center">
            <div>
              <p class="text-h5 mb-2 text-center">Registro Exitoso</p>
              <p class="mb-3">Su registro se finalizó correctamente, la siguiente información es acerca de su visita programada. </p>
              <p class="mb-2">Lugar de la visita: <strong class="grey--text"> {{ scheduleVisitSede.Campus[0].name }} </strong></p>
              <p>Intervalo de fecha y horario habilitado para su ingreso:</p>
              <p>Desde: <strong class="grey--text">{{ scheduleVisit.start_date.substring(0, 10) }}</strong> a las: <strong class="grey--text">{{ scheduleVisit.start_date.substring(11, 16) }}</strong></p>
              <p>Hasta: <strong class="grey--text">{{ scheduleVisit.finish_date.substring(0, 10) }}</strong> a las: <strong class="grey--text">{{ scheduleVisit.finish_date.substring(11, 16) }}</strong></p>
              <div v-if="scheduleVisit.vehicle == true">
                <p class="mt-2">Adicionalmente se habilitó la celda <strong class="grey--text">{{ scheduleVisit.parking_cell }}</strong> para el vehículo con placa: <strong class="grey--text">{{ scheduleVisit.plate }}</strong></p>
              </div>
            </div>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12" class="text-center">
            <v-btn
              rounded
              color="primary"
              class="px-4 mx-2 primaryBtn"
              @click="clearRegistry"
            >
              TERMINAR
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="QRGenerated" class="pt-5">
            <v-col cols="12" md="6" class="d-flex justify-center align-center">
            <p class="font-weight-medium text-center text-h6">
                Ubique este código QR en el lector del dispositivo para proceder con
                su registro.
            </p>
            </v-col>
            <v-col cols="12" md="6">
            <qrcode-vue
                class="d-flex justify-center align-center"
                :value="QRValue"
                :size="size"
                level="H"
            />
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-center align-center">
            <v-btn
                block
                color="error"
                class="mr-4"
                @click="
                QRGenerated = false;
                QRValue = '';
                registry.id = ''
                "
            >
                ATRAS
            </v-btn>
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-center align-center">
            <v-btn block color="primary" class="mr-4" @click="clearRegistry">
                GENERAR NUEVO QR
            </v-btn>
            </v-col>
        </v-row>
    </v-card-text>
    <v-snackbar v-model="snackbar" timeout="10000" :color="snackbarColor" top>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
      </div>
    </v-container>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import {S3} from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import globals from "../../globals";
import axios from "axios";
import TakePhoto from "../utils/take-photo.vue"
import HabeasData from "./habeas-data.vue"
//import auth from "../../services/auth";
//import backlog from "../services/logs";

export default {
  name: "RegisterUsers",
  components: {
    QrcodeVue,
    TakePhoto,
    HabeasData
  },
  data: () => ({
    datepicker1: false,
    expeditionDatePicker: "",
    QRValue: "",
    size: 250,
    QRGenerated: false,
    valid: true,
    snackbarText: "",
    snackbarColor: "",
    snackbar: false,
    namesRule: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => /^[A-Za-z _]*[A-Za-z][A-Za-z _]*$/.test(v) || "El texto no debe tener caracteres especiales, tildes o números.",
    ],
    secondNameRule: [
      (v) => /^$|[A-Za-z _]*[A-Za-z][A-Za-z _]*$/.test(v) || "El texto no debe tener caracteres especiales, tildes o números.",
    ],
    ccRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => (v && v.length >= 6) || "El documento debe tener minimo 6 digitos.",
      (v) => /^[0-9]*$/.test(v) || "El documento solo debe tener números",
    ],
    passRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => (v && v.length >= 7) || "El documento debe tener minimo 7 digitos.",
      (v) => /^[0-9A-Za-z _]*[0-9A-Za-z][0-9A-Za-z _]*$/.test(v) || "El documento no debe tener caracteres especiales o tildes.",
    ],
    phoneRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => (v && v.length == 10) || "El número celular debe tener 10 digitos.",
      (v) => /^[0-9]*$/.test(v) || "El campo solo permite números",
    ],
    emailRules: [
      (v) => !!v || "El campo es obligatorio.",
      (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "El correo electronico no esta formado correctamente",
    ],
    documentTypes: [
      { Type: "CC", Title: "Cédula de Ciudadanía" },
      { Type: "CE", Title: "Cédula de Extranjería" },
      { Type: "PPT", Title: "PPT" },
      { Type: "TI", Title: "Tarjeta de Identidad" },
      { Type: "PASS", Title: "Pasaporte" },
    ],
    genres: [
      { Type: "M", Title: "MASCULINO" },
      { Type: "F", Title: "FEMENINO" },
    ],
    bloodTypes: ["A+", "B+", "O+", "AB+", "A-", "B-", "O-", "AB-"],
    registry: {
      tipoDocumento: "",
      id: "",
      firstname: "",
      secondname: "",
      surname1: "",
      surname2: "",
      birthdate: "",
      expeditionDate: "",
      sex: "",
      blood: "",
      phone: "",
      email: "",
    },
    userUUID: "",
    companyID: null,
    roleID: null,
    suborgID: null,
    loadingSelfieBtn: false,
    loadingFrontDocBtn: false,
    loadingBackDocBtn: false,
    loadingRegistryCreationBtn: false,

    selfieImg: null,
    frontDocImg: null,
    backDocImg: null,

    infoPanel: true,
    firstPanel: false,
    registered_person_panel: false,
    secondPanel: false,
    intermediaryPanel: false,
    thirdPanel: false,
    fourthPanel: false,
    successRegistrationVisitorPanel: false,

    selfieBase64: null,
    frontDocBase64: null,
    backDocBase64: null,

    s3SelfieKey: "",
    s3FrontDocKey: "",
    s3BackDocKey: "",

    selfieFile: null,
    frontDocFile: null,
    backDocFile: null,

    registered_person: "",
    scheduleVisit: null,
    scheduleVisitSede: null,
    
    allowedAccess: false,
    todayDate: globals.COdate().substring(0, 10),
    habeasData: false,
    habeasDataDialog: false,
    iReadPolicy: false,
    empresaHabeasID: "",
    // * -------------------------------------
    selfiePhotoModule1: true,
    selfiePhotoModule2: false,
    frontDocumentPhotoModule1: true,
    frontDocumentPhotoModule2: false,
    backDocumentPhotoModule1: true,
    backDocumentPhotoModule2: false,
    isVisitor: false,
  }),
  computed: {
    config() {
      return {
        bucketName: "vanguard-id",
        region: process.env.VUE_APP_S3_REGION,
        accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
        signatureVersion: 'v4',
      }
    },

    S3Client() {
      return new S3(this.config)
    }
  },
  methods: {
    // ** ----------------------------------------------------------------
    validateAccess() {
      if (this.$route.query.exp) {
        let expirationCode = this.$route.query.exp;
        let expirationTimestamp = expirationCode.substring(3, expirationCode.length)
        expirationTimestamp = parseInt(expirationTimestamp)
        let timestampToDate = new Date(expirationTimestamp).toISOString();

        if (this.todayDate <= timestampToDate.substring(0, 10)) {
          this.allowedAccess = true
          this.$route.query.id ? this.companyID = this.$route.query.id : "";
  
          this.$route.query.id2 ? this.roleID = this.$route.query.id2 : "";
  
          this.$route.query.id3 ? this.suborgID = this.$route.query.id3 : "";

          if (this.$route.query.id == 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17') {
            this.empresaHabeasID = this.$route.query.pl
          }
  
          if (this.$route.query.id4) {
            this.getScheduledVisit(this.$route.query.id4)
          }
          this.userUUID = uuidv4()
          if (this.userUUID == '' || this.userUUID == undefined || this.userUUID == null) {
            this.userUUID = uuidv4()
          }
        }
      }
    },

    validateSelfieNew(data) {
      if (data && data.key) {
        //console.log("validateSelfieNew: ", data)
        this.s3SelfieKey = data.key;
      }
    },

    validateFrontPicNew(data) {
      if (data && data.key) {
        //console.log("validateFrontPicNew: ", data)
        this.s3FrontDocKey = data.key;
      }
    },

    validateBackNew(data) {
      if (data && data.key) {
        this.s3BackDocKey = data.key
      }
    },

    checkSelfie() {
      this.loadingSelfieBtn = true

      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/auth-person",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          login_img_key: this.s3SelfieKey,
        },
      }).then((res) => {
          if (res.data.document_number) {
            this.firstPanel = false;
            this.registered_person_panel = true;
            this.loadingSelfieBtn = false;
            this.registered_person = res.data.document_number;
          } else {
            this.checkFaceFeatures();
          }
        })
        .catch((err) => {
          //console.log(err.response.data.Message)
          if (err.response.data.Message === "No matching faces.") {
            this.checkFaceFeatures();
          } else {
            this.loadingSelfieBtn = false
          }
        });
    },

    checkFrontDoc() {
      this.loadingFrontDocBtn = true;
      /* this.secondPanel = false
      this.intermediaryPanel = true */
      // this.compareFaces(); //TODO: COMMENTED FOR A WHILE
      //this.documentHasFace();
      if (this.registry.tipoDocumento == "CC" || this.registry.tipoDocumento == "TI" || this.registry.tipoDocumento == "CE") {
        axios({
          method: 'POST',
          baseURL: globals.APIURL + "identity/extract-id-number",
          headers: {
            "Access-Control-Allow-Origin": "*"
          },
          data: {
            FrontDocumentImage: this.s3FrontDocKey,
          },
          }).then((res) => {
            if (res.data) {
              if (res.data.typeIsCorrect === true) {
                let cedula = res.data.textDetectData.DocumentNumber.replace(/\./gi, "")
                //console.log(res.data)
                //console.log(cedula)
                this.registry.id = cedula ? cedula : "";
                if (cedula !== "") {
                  this.searchPersonData(cedula)
                }
                this.loadingFrontDocBtn = false;
                this.secondPanel = false;
                this.intermediaryPanel = false;
                this.thirdPanel = true;
              } else {
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText = "El documento no es una cédula, por favor verifique que el documento se vea completo en la imagen y sea el correcto.";
                this.loadingFrontDocBtn = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.loadingFrontDocBtn = false;
          });
      } else if (this.registry.tipoDocumento == "PASS") {
        axios({
          method: 'POST',
          baseURL: globals.APIURL + "identity/extract-pass-data",
          headers: {
            "Access-Control-Allow-Origin": "*"
          },
          data: {
            FrontDocumentImage: this.s3FrontDocKey,
          },
          }).then((res) => {
            if (res.data) {
              if (res.data.typeIsCorrect === true) {
                // console.log("ID PASSPORT", res.data[0].DetectedText)
                let document = res.data.passExtraction.length > 0 ? res.data.passExtraction[0].DetectedText : ""
                this.registry.tipoDocumento = 'PASS'
                this.registry.id = document ? document : "";
                this.loadingFrontDocBtn = false;
                this.secondPanel = false;
                this.intermediaryPanel = false;
                this.fourthPanel = true;
              } else {
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText = "El documento no es un pasaporte, por favor verifique que el documento se vea completo en la imagen y sea el correcto.";
                this.loadingFrontDocBtn = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.loadingFrontDocBtn = false;
          });
      } else if (this.registry.tipoDocumento == "PPT") {
        axios({
          method: "POST",
          baseURL: globals.APIURL + "identity/extract-ppt-data",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
          data: {
            FrontDocumentImage: this.s3FrontDocKey,
          },
        })
          .then((res) => {
            if (res.data) {
              if (res.data.typeIsCorrect === true) {
                // console.log("ID CE o PPT", res.data.pptDetection);
                let document = res.data.pptDetection && res.data.pptDetection !== '' ? res.data.pptDetection : "";
                //this.registry.tipoDocumento = "CE";
                this.registry.id = document ? document : "";
                this.loadingFrontDocBtn = false;
                this.secondPanel = false;
                this.intermediaryPanel = false;
                this.fourthPanel = true;
              } else {
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText = "El documento no es PPT, por favor verifique que el documento se vea completo en la imagen y sea el correcto.";
                this.loadingFrontDocBtn = false;
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.loadingFrontDocBtn = false;
          });
      }
    },

    checkBackDoc() {
      this.thirdPanel = false
      this.fourthPanel = true
    },
    // ** ----------------------------------------------------------------
    /* ---------- SELFIE READ IMG CODE BLOCK ---------- */
    clickSelfieInput() {
      this.$refs.selfieInput.click();
    },

    readSelfieFile(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        //this.userUUID = uuidv4()
        this.selfieImg = fileReader.result;
        //this.selfieFile = files[0]
        this.resizeAndCompressBase64Img1(this.selfieImg)
        //const searchTerm = "base64,";
        //let base64Code = fileReader.result;
        //this.selfieBase64 = base64Code.substr(base64Code.indexOf(searchTerm) + searchTerm.length);
      });
      fileReader.readAsDataURL(files[0]);
    },

    /* ---------- FRONT DOC READ IMG CODE BLOCK ---------- */
    clickFrontDocInput() {
      this.$refs.frontDocInput.click();
    },

    readFrontDocFile(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.frontDocImg = fileReader.result;
        //this.frontDocFile = files[0]
        this.resizeAndCompressBase64Img2(this.frontDocImg)
        //const searchTerm = "base64,";
        //let base64Code = fileReader.result;
        //this.frontDocBase64 = base64Code.substr(base64Code.indexOf(searchTerm) + searchTerm.length);
      });
      fileReader.readAsDataURL(files[0]);
    },

    /* ---------- BACK DOC READ IMG CODE BLOCK ---------- */
    clickBackDocInput() {
      this.$refs.backDocInput.click();
    },

    readBackDocFile(e) {
      const files = e.target.files;
      let filename = files[0].name;

      if (filename.lastIndexOf(".") <= 0) {
        console.log("Insert a valid image.");
      }

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.backDocImg = fileReader.result;
        //this.backDocFile = files[0]
        this.resizeAndCompressBase64Img3(this.backDocImg)
        //const searchTerm = "base64,";
        //let base64Code = fileReader.result;
        //this.backDocBase64 = base64Code.substr(base64Code.indexOf(searchTerm) + searchTerm.length);
      });
      fileReader.readAsDataURL(files[0]);
    },

    /* ---------- VALIDATE MASK & GLASSES ---------- */
    resizeAndCompressBase64Img1(base64) {
        console.log("ENTRE AL 1RO")
        let resizingFactor = 0.5;
        const canvas1 = document.createElement("canvas");
        const context1 = canvas1.getContext("2d");
        let img1 = document.createElement("img");
        img1.src = base64;

        const originalWidth = img1.width;
        const originalHeight = img1.height;

        const canvasWidth = originalWidth * resizingFactor;
        const canvasHeight = originalHeight * resizingFactor;

        canvas1.width = canvasWidth;
        canvas1.height = canvasHeight;

        context1.drawImage(
            img1,
            0,
            0,
            originalWidth * resizingFactor,
            originalHeight * resizingFactor
        ); 

        canvas1.toBlob((blob1) => {
            console.log("ENTRE AL 1ER CANVAS BLOB!!")
            console.log(blob1)
            if (blob1) {
                this.selfieFile = blob1;
                console.log("1111: ", this.selfieFile)
            } else {
                this.resizeAndCompressBase64Img1(base64)
            }
        },
        "image/jpeg",
        0.6)
    },

    resizeAndCompressBase64Img2(base64) {
        console.log("ENTRE AL 2DO")
        let resizingFactor = 0.5;
        const canvas2 = document.createElement("canvas");
        const context2 = canvas2.getContext("2d");
        let img2 = document.createElement("img");
        img2.src = base64;

        const originalWidth = img2.width;
        const originalHeight = img2.height;

        const canvasWidth = originalWidth * resizingFactor;
        const canvasHeight = originalHeight * resizingFactor;

        canvas2.width = canvasWidth;
        canvas2.height = canvasHeight;

        context2.drawImage(
            img2,
            0,
            0,
            originalWidth * resizingFactor,
            originalHeight * resizingFactor
        );

        canvas2.toBlob((blob2) => {
            console.log("ENTRE AL 2DO CANVAS BLOB")
            console.log(blob2)
            if (blob2) {
                this.frontDocFile = blob2;
                console.log("2: ", this.frontDocFile)
            } else {
                this.resizeAndCompressBase64Img2(base64)
            }
        },
        "image/jpeg",
        0.8)
    },

    resizeAndCompressBase64Img3(base64) {
        console.log("ENTRE AL 3RO")
        let resizingFactor = 0.5;
        const canvas3 = document.createElement("canvas");
        const context3 = canvas3.getContext("2d");
        let img3 = document.createElement("img");
        img3.src = base64;

        const originalWidth = img3.width;
        const originalHeight = img3.height;

        const canvasWidth = originalWidth * resizingFactor;
        const canvasHeight = originalHeight * resizingFactor;

        canvas3.width = canvasWidth;
        canvas3.height = canvasHeight;

        context3.drawImage(
            img3,
            0,
            0,
            originalWidth * resizingFactor,
            originalHeight * resizingFactor
        );

        canvas3.toBlob((blob3) => {
            console.log("ENTRE AL 3ER CANVAS BLOB")
            console.log(blob3)
            if (blob3) {
                this.backDocFile = blob3;
                console.log("3: ", this.backDocFile)
            } else {
                this.resizeAndCompressBase64Img3(base64)
            }
        },
        "image/jpeg",
        0.8)
    },

    validateSelfie() {
      this.loadingSelfieBtn = true
      let name = "selfie_" + this.userUUID + ".jpg";

      this.S3Client.upload({
          Bucket: "vanguard-id",
          Key: name,
          Body: this.selfieFile,//Buffer.from(esto, 'base64'),
          ContentType: 'image/jpeg',
        },  (err, data) => {
          if (err) {
            console.log(err)
            this.loadingSelfieBtn = false
          }

          this.s3SelfieKey = data.key;
          this.checkSelfie();
          /* console.log("Respuesta de imagen subida a S3 en la validacion de la selfie", data)
          // ******************************************************************
            axios({
              method: 'POST',
              baseURL: globals.APIURL + "identity/auth-person",
              headers: {
                  "Access-Control-Allow-Origin": "*"
              },
              data: {
                login_img_key: this.s3SelfieKey,
              },
            }).then((res) => {
                console.log(res)
                if (res.data.document_number) {
                  this.firstPanel = false;
                  this.registered_person_panel = true;
                  this.registered_person = res.data.document_number;
                  this.loadingSelfieBtn = true
                } else {
                  this.checkFaceFeatures();
                }
              })
              .catch((err) => {
                console.log(err.response.data.Message)
                if (err.response.data.Message === "No matching faces.") {
                  this.checkFaceFeatures();
                }
              });
            // ****************************************************************** */
        });
    },

    checkFaceFeatures() {
      axios({
        method: 'POST',
        baseURL: globals.APIURL + "identity/dvf",
        headers: {
            "Access-Control-Allow-Origin": "*"
        },
        data: {
          SelfieImage: this.s3SelfieKey,
        },
      }).then((res) => {
          console.log(res.data)
          if (res.data) {
            if (
              res.data.faceAnalysisData.FaceDetails &&
              res.data.faceAnalysisData.FaceDetails.length > 0
            ) {
              if (
                !res.data.ppeData.Summary.PersonsWithRequiredEquipment ||
                res.data.ppeData.Summary.PersonsWithRequiredEquipment.length ==
                  0
              ) {
                if (
                  res.data.faceAnalysisData.FaceDetails[0].Eyeglasses.Value ==
                    false &&
                  res.data.faceAnalysisData.FaceDetails[0].Sunglasses.Value ==
                    false
                ) {
                  this.loadingSelfieBtn = false;
                  this.firstPanel = false;
                  this.secondPanel = true;
                } else {
                  this.loadingSelfieBtn = false
                  this.snackbar = true;
                  this.snackbarColor = "error";
                  this.snackbarText =
                    "La selfie tiene lentes, favor ingresar una sin ellos.";
                }
              } else {
                this.loadingSelfieBtn = false
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText =
                  "La selfie tiene tapabocas, favor ingresar una sin el.";
              }
            } else {
              this.loadingSelfieBtn = false
              this.snackbar = true;
              this.snackbarColor = "error";
              this.snackbarText =
                "La imagen ingresada no tiene un rostro reconocible.";
            }
          }
        })
        .catch((err) => {
          this.loadingSelfieBtn = false
          console.log(err);
        });
    },

    /* ---------- VALIDATE FRONT DOC DATA ---------- */
    validateFrontDocumentImg() {
      this.loadingFrontDocBtn = true;
      let name = "front-doc_" + this.userUUID + ".jpg"

        this.S3Client.upload({
          Bucket: "vanguard-id",
          Key: name,
          Body: this.frontDocFile,//Buffer.from(esto, 'base64'),
          ContentType: 'image/jpeg',
        },  (err, data) => {
          if (err) {
            console.log(err)
            this.loadingFrontDocBtn = false
          }

          this.s3FrontDocKey = data.key;
          this.checkFrontDoc()
          /* // this.compareFaces(); //TODO: COMMENTED FOR A WHILE
          this.documentHasFace();
            if (this.registry.tipoDocumento == "CC") {
                axios({
                    method: 'POST',
                    baseURL: globals.APIURL + "identity/extract-id-number",
                    headers: {
                        "Access-Control-Allow-Origin": "*"
                    },
                    data: {
                        FrontDocumentImage: data.key,
                    },
                    }).then((res) => {
                        if (res.data) {
                            let cedula = res.data.textDetectData.DocumentNumber.replace(/\./gi, "")
                            console.log(res.data)
                            console.log(cedula)
                            this.registry.id = cedula ? cedula : "";
                            if (cedula !== "") {
                                this.searchPersonData(cedula)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } */
        });
    },

    documentHasFace() {
        axios({
            method: 'POST',
            baseURL: globals.APIURL + "identity/vfd",
            headers: {
                "Access-Control-Allow-Origin": "*"
            },
            data: {
            FrontDocumentImage: this.s3FrontDocKey,
            },
        }).then((res) => {
          if (res.data) {
            if (res.data.faceAnalysisData.FaceDetails && res.data.faceAnalysisData.FaceDetails.length > 0) {
                  //console.log("Respuesta de validacion de rostro: ", res)
                    if (this.registry.tipoDocumento !== 'PASS' && this.registry.tipoDocumento !== 'PPT') {
                      this.loadingFrontDocBtn = false;
                      this.secondPanel = false;
                      this.intermediaryPanel = false;
                      this.thirdPanel = true;
                    } else {
                      this.loadingFrontDocBtn = false;
                      this.secondPanel = false;
                      this.intermediaryPanel = false;
                      this.fourthPanel = true;
                    }
                } else {
                    this.loadingFrontDocBtn = false;
                    this.secondPanel = true;
                    this.intermediaryPanel = false;
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText =
                        "La imagen del documento no tiene un rostro reconocible, por favor intente nuevamente con otra imagen.";
                }
            }
        }).catch((err) => {
            this.loadingFrontDocBtn = false;
            console.log(err);
        });
    },

    /* ---------- COMPARE SELFIE AND DOCUMENT FACE ---------- */

    compareFaces() {
        axios
          .post(globals.APIURL + "identity/cf", {
            SelfieImage: this.s3SelfieKey,
            DocumentImage: this.s3FrontDocKey,
          }, {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })
          .then((res) => {
            if (res.data.FaceMatches && res.data.FaceMatches.length > 0) {
                console.log(res.data.FaceMatches)
              this.similarityNumber = Math.round(res.data.FaceMatches[0].Similarity);
              if (Math.round(res.data.FaceMatches[0].Similarity) > 40) {
                this.loadingFrontDocBtn = false;
                this.secondPanel = false;
                this.thirdPanel = true;
              } else {
                this.loadingFrontDocBtn = false;
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText =
                  "El rostro del documento no coincide con la selfie.";
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },

    /* ---------- VALIDATE BACK DOC DATA ---------- */
    validateBackDocumentImg() {
      this.loadingBackDocBtn = true;
      let name = "back-doc_" + this.userUUID + ".jpg";

      this.S3Client.upload({
          Bucket: "vanguard-id",
          Key: name,
          Body: this.backDocFile,//Buffer.from(esto, 'base64'),
          ContentType: 'image/jpeg',
        },  (err, data) => {
          if (err) {
            console.log(err)
            console.log(data)
            this.loadingBackDocBtn = false;
          }

          console.log("Respuesta de imagen subida a S3 en la validacion de la trasera", data)
          this.loadingBackDocBtn = false;
          this.thirdPanel = false
          this.fourthPanel = true
        });
      // **---------------------------------------

      /* this.S3Client
        .uploadFile(this.backDocFile, "back-doc_" + this.userUUID)
        .then(data => {
          if (data.key && data.key != '') {
            this.loadingBackDocBtn = false;
            this.thirdPanel = false
            this.fourthPanel = true
          }
        })
        .catch(err => {
          this.loadingBackDocBtn = false;
          console.log(err)
        }) */
    },

    searchPersonData(document_number) {
        axios
          .post(globals.APIURL + "identity/find-person-data", {
            document_number: document_number,
          }, {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })
          .then((res) => {
            if (res.status == 200 && res.data) {
                let names = res.data.names
                this.registry.firstname = names[0];
                this.registry.secondname = names.length == 3 ? "" : names[1];
                this.registry.surname1 = names.length == 3 ? names[1] : names[2];
                this.registry.surname2 = names.length == 3 ? names[2] : names[3];
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },

    createRegistry(reg) {
      if (this.$refs.form.validate() == true) {
        this.loadingRegistryCreationBtn = true;
        /* if (reg.id.length == 7) {
          reg.id = "000" + reg.id;
        } else if (reg.id.length == 8) {
          reg.id = "00" + reg.id;
        } else if (reg.id.length == 9) {
          reg.id = "0" + reg.id;
        } */
        reg.expeditionDate = this.expeditionDatePicker
        reg.firstname = reg.firstname.toUpperCase().trim();
        reg.secondname = reg.secondname.toUpperCase().trim();
        reg.surname1 = reg.surname1.toUpperCase().trim();
        reg.surname2 = reg.surname2.toUpperCase().trim();
        if (reg.tipoDocumento === "PPT") {
          reg.tipoDocumento = "CE"
        }
        axios
          .post(globals.APIURL + "identity/link-register-user", {
            user_data: reg,
            selfie_key: this.s3SelfieKey,
            images_ID: this.userUUID,
            company_id: this.companyID,
            role_id: this.roleID,
            suborg_id: this.suborgID,
          }, {
          headers: {
            "Access-Control-Allow-Origin": "*"
          }
        })
          .then((res) => {
            console.log("RESPUESTA DE LA CREACIÓN: ", res)
            if (this.scheduleVisit !== null) {
              this.loadingRegistryCreationBtn = false;
              this.fourthPanel = false;
              this.successRegistrationVisitorPanel = true;
              this.snackbar = true;
              this.snackbarColor = "success";
              this.snackbarText =
                "Registro creado.";
            } else {
              this.clearRegistry()
              this.loadingRegistryCreationBtn = false;
              this.snackbar = true;
              this.snackbarColor = "success";
              this.snackbarText =
                "Se creo el registro de manera exitosa.";
            }
              //this.$bus.$emit("updateRegistry", "Update register list");
            /* if (res.data.status == 200) {
              console.log("RESPUESTA DE LA CREACIÓN: ", res)
              this.clearRegistry()
              this.loadingRegistryCreationBtn = false;
              this.snackbar = true;
              this.snackbarColor = "success";
              this.snackbarText =
                "Se creo el registro de manera exitosa.";
              //this.$bus.$emit("updateRegistry", "Update register list");
            } */
          })
          .catch((err) => {
            this.loadingRegistryCreationBtn = false;
            console.log(err);
          });
      }
    },

    generateQR(reg) {
      console.log(reg)
      console.log(this.selfieImg)
      console.log(this.frontDocImg)
      console.log(this.backDocImg)
    
      /* if (this.$refs.form.validate() == true) {
          if (reg.id.length == 7) {
            reg.id = "000" + reg.id;
          } else if (reg.id.length == 8) {
            reg.id = "00" + reg.id;
          } else if (reg.id.length == 9) {
            reg.id = "0" + reg.id;
          }
        reg.birthdate = this.birthDatePicker.split("-").reverse().join("-");
        reg.firstname = reg.firstname.toUpperCase().trim();
        reg.secondname = reg.secondname.toUpperCase().trim();
        reg.surname1 = reg.surname1.toUpperCase().trim();
        reg.surname2 = reg.surname2.toUpperCase().trim();
        const str = JSON.stringify(reg, null, 0);
        this.QRValue = "QR" + str;
        this.QRGenerated = true;
        this.QRValue = this.QRValue.normalize("NFD").replace(/[\u0300-\u0302]/g, "");
        this.QRValue = this.QRValue.normalize("NFD").replace(/[\u0304-\u036f]/g, "");
        this.QRValue = this.QRValue.replace(/Ñ/g, "%");
        backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "create/QR/registry"})
      } */
    },

    clearRegistry() {
      this.registry.tipoDocumento = "";
      this.registry.id = "";
      this.registry.firstname = "";
      this.registry.secondname = "";
      this.registry.surname1 = "";
      this.registry.surname2 = "";
      this.registry.expeditionDate = "";
      this.registry.sex = "";
      this.registry.blood = "";
      this.registry.phone = "";
      this.registry.email = "";
      this.expeditionDatePicker = "";
      this.QRGenerated = false;
      this.QRValue = "";
      this.valid = true;
      // *************
      this.userUUID = "";
      this.loadingSelfieBtn = false;
      this.loadingFrontDocBtn = false;
      this.loadingBackDocBtn = false;

      this.selfieImg = null;
      this.frontDocImg = null;
      this.backDocImg = null;

      this.infoPanel = true;
      this.firstPanel = false;
      this.registered_person_panel = false;
      this.secondPanel = false;
      this.thirdPanel = false;
      this.fourthPanel = false;
      this.successRegistrationVisitorPanel = false;

      this.selfieBase64 = null;
      this.frontDocBase64 = null;
      this.backDocBase64 = null;

      this.s3SelfieKey = "";
      this.s3FrontDocKey = "";
      this.s3BackDocKey = "";

      this.selfieFile = null;
      this.frontDocFile = null;
      this.backDocFile = null;

      this.registered_person = "";
      this.scheduleVisit = null;
      this.scheduleVisitSede = null;
    },

    getScheduledVisit(ID) {
      axios
        .post(globals.APIURL + "entry/get-schedule-visit", {
          schedule_visit_id: ID,
        }, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      })
        .then((res) => {
          if (res.status == 200) {
            console.log("RESPUESTA DE VISITA PROGRAMADA: ", res.data)
            this.isVisitor = true;
            this.scheduleVisit = res.data
            this.getSedeInfo(res.data)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSedeInfo(scheduleData) {
      axios
        .post(globals.APIURL + "org/foc", {
          company_id: scheduleData.company_id,
          campus_id: scheduleData.sede_id
        }, {
        headers: {
          "Access-Control-Allow-Origin": "*"
        }
      })
        .then((res) => {
          if (res.status == 200) {
            console.log("RESPUESTA DE CAMPUS: ", res.data)
            this.scheduleVisitSede = res.data
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.validateAccess()
    this.$bus.$on("acceptRegisterHabeasData", (acceptanceHabeasData) => {
      this.habeasData = acceptanceHabeasData
      //this.habeasDataDialog = false;
    });
  },
};
</script>