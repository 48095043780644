<template>
  <v-container fluid>
    <div v-if="eventDetail">
      <v-dialog v-model="eventDetail" content-class="modalVs" :max-width="entrySelected.access_type == 'unrecognized' || entrySelected.access_type == 'denied_by_company' ? '700px' : '650px'">
        <v-card>
          <v-card-title>
            <span v-if="entrySelected && entrySelected.access_type !== 'unrecognized' && entrySelected.access_type !== 'denied_by_company'" class="text-h6">Información de la persona</span>
            <span v-else class="text-h6">Información de no registrado recurrente</span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col v-if="entrySelected && entrySelected.access_type !== 'unrecognized' && entrySelected.access_type !== 'denied_by_company'" cols="12" md="6" class="text-center">
                <p class="font-weight-light pb-2 secondary--text" style="font-size: 1.15rem">IMG. REGISTRO</p>
                <div class="avatarAcces2">
                  <v-img
                    style="max-height: 250px; max-width: 600px"
                    :src="PSRegistryImg"
                    lazy-src="../assets/loading.png"
                    @error="imgErrorHandler"
                    contain
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-col>
              <v-col cols="12" :md="entrySelected && entrySelected.access_type !== 'unrecognized' && entrySelected.access_type !== 'denied_by_company' ? 6 : 12" class="text-center">
                <p v-if="lunchModule" class="font-weight-light pb-2 secondary--text" style="font-size: 1.15rem">IMG. ÚLTIMA ENTREGA</p>
                <p v-if="!lunchModule" class="font-weight-light pb-2 secondary--text" style="font-size: 1.15rem">IMG. ÚLTIMO ACCESO</p>
                <div class="avatarAcces2">
                  <v-img
                    style="max-height: 250px; max-width: 600px"
                    :src="PSDailyImg"
                    lazy-src="../assets/loading.png"
                    @error="'../assets/loading.png'"
                    contain
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="entrySelected.access_type != 'unrecognized' && entrySelected.access_type != 'denied_by_company'">
              <v-col
                cols="12"
                md="12"
                class="text-center py-0"
                title="DOCUMENTO"
              >
                <span class="text-subtitle-1">
                  <strong class="grey--text">N° Documento: </strong>
                  {{ personSelected ? personSelected.document_number : '---'}}
                </span>
              </v-col>
              <v-col
                cols="12"
                md="12"
                class="text-center py-0"
                title="NOMBRE"
              >
                <span class="text-subtitle-1">
                  <strong class="grey--text">Nombre: </strong>
                  {{
                    personSelected ? personSelected.first_name + " " + (personSelected.second_name ? personSelected.second_name : "") + " " + personSelected.first_last_name + " " + (personSelected.second_last_name ? personSelected.second_last_name : "") : '---'
                  }}
                </span>
              </v-col>
              <v-col
                cols="12"
                md="12"
                class="text-center py-0"
                title="TIPO SE USUARIO"
              >
                <span class="text-subtitle-1">
                  <strong class="grey--text">T. Usuario: </strong>
                  <span v-for="(item, i) in permissionGroupsList" :key="i">{{
                    personSelected && personSelected.company_person.pin == item.uuid ? item.name : ''
                  }}</span>
                </span>
              </v-col>
              <v-col
                v-if="userOrgName == 'solla'"
                cols="12"
                md="12"
                class="text-center py-0"
                title="ÚLTIMA ENCUESTA"
              >
                <span class="text-subtitle-1">
                  <strong class="grey--text">Última Encuesta: </strong>
                  <span class="text-body-1">
                    <strong v-if="last_survey && last_survey.created_date" :class="glbs.validateSurveyStatus(last_survey.created_date) ? 'success--text' : 'red--text'">{{ last_survey.created_date.substring(0, 10) + " - " + last_survey.created_date.substring(11, 16) }}</strong>
                    <strong v-else>N/R</strong>
                  </span>
                </span>
              </v-col>
              <v-col cols="12" class="pa-2 ma-0 text-center">
                <v-btn
                  color="primary"
                  rounded
                  x-small
                  @click="goToDetailedProfile(personSelected)"
                >
                  Ver Perfil Detallado
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="entrySelected.access_type == 'unrecognized' || entrySelected.access_type == 'denied_by_company'">
              <v-col cols="12">
                <v-layout child-flex>
                <v-data-table
                  :headers="headersNN"
                  :items="nnEntries"
                  :items-per-page="10"
                  :header-props="{
                    mobile: true,
                  }"
                  :footer-props="{
                    'items-per-page-options': [10],
                  }"
                  class="dtTable"
                  fixed-header
                  no-data-text="No hay eventos que mostrar"
                >
                  <template v-slot:[`item.ImgAccess`]="{ item }">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          size="45"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-img
                            :src="getDailyImg(item)"
                            lazy-src="../assets/loading.png"
                            @error="'../assets/loading.png'"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </template>
                      <v-img
                        :src="getDailyImg(item)"
                        width="200"
                        lazy-src="../assets/loading.png"
                        @error="'../assets/loading.png'"
                      >
                       <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="black"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.asset`]="{ item }">
                    <div v-for="(zone, j) in sedes" :key="j">
                      {{ item.asset === zone.uuid ? zone.name : "" }}
                    </div>
                  </template>
                  <template v-slot:[`item.type_entry`]="{ item }">
                    <v-chip
                      class="small-chip ma-0"
                      :color="item.type_entry === 'ENTRY' ? 'primary' : 'red'"
                      text-color="white"
                      small
                    >
                      {{ item.type_entry === "ENTRY" ? "Acceso" : "Salida" }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.created_time`]="{ item }" >
                    {{
                      item.created_time.substring(0, 10)
                    }} - {{
                      item.created_time.substring(11, 16)
                    }}
                  </template>
                </v-data-table>
              </v-layout>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <v-layout child-flex>
                  <v-data-table
                    :headers="headersNN"
                    :items="soloEntries"
                    :items-per-page="20"
                    :header-props="{
                      mobile: true,
                    }"
                    :footer-props="{
                      'items-per-page-options': [20],
                    }"
                    class="dtTable"
                    fixed-header
                    no-data-text="No hay eventos que mostrar"
                  >
                    <template v-slot:[`item.ImgAccess`]="{ item }">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar
                            size="45"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-img
                              :src="getDailyImg(item)"
                              lazy-src="../assets/loading.png"
                              @error="'../assets/loading.png'"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="black"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-avatar>
                        </template>
                        <v-img
                          :src="getDailyImg(item)"
                          width="200"
                          lazy-src="../assets/loading.png"
                          @error="'../assets/loading.png'"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.asset`]="{ item }">
                      <div v-for="(zone, j) in sedes" :key="j">
                        {{ item.asset === zone.uuid ? zone.name : "" }}
                      </div>
                    </template>
                    <template v-slot:[`item.type_entry`]="{ item }">
                      <v-chip
                        class="small-chip ma-0"
                        :color="item.type_entry === 'ENTRY' ? 'primary' : 'red'"
                        text-color="white"
                        small
                      >
                        {{ item.type_entry === "ENTRY" ? "Acceso" : "Salida" }}
                      </v-chip>
                    </template>
                    <template v-slot:[`item.created_time`]="{ item }" >
                      {{
                        item.created_time.substring(0, 10)
                      }} - {{
                        item.created_time.substring(11, 16)
                      }}
                    </template>
                  </v-data-table>
                </v-layout>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="asignPeopleDialog">
      <v-dialog v-model="asignPeopleDialog" content-class="modalVs" max-width="500px" persistent>
        <v-card>
          <v-card-title>
            <span class="text-h5">Asignar Comidas</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label class="text-h6">Autoriza: </label>
                  <span class="text-body-1 pl-4">{{ personInCharge.first_name + " " + personInCharge.first_last_name }}</span>
                  <label class="text-body-2 pl-4">{{ personInCharge.document_number }}</label>
                </v-col>
                <v-col cols="12">
                  <label>Seleccione las personas que recibiran las comidas: </label>
                  <v-autocomplete
                    v-model="peopleToAsign"
                    :items="registries"
                    return-object
                    label="Empleados"
                    :filter="customFilter"
                    small-chips
                    multiple
                    outlined
                    dark
                    dense
                    color="secondary"
                    hide-details=""
                  >
                    <template v-slot:selection="item">
                      <v-chip
                        v-bind="item.attrs"
                        close
                        class="ma-1"
                        :input-value="item.selected"
                        @click="item.select"
                        @click:close="remove(item.item)"
                      >
                        {{
                          item.item.first_name +
                          " " +
                          (item.item.second_name
                            ? item.item.second_name + " "
                            : "") +
                          item.item.first_last_name +
                          " " +
                          item.item.second_last_name
                        }}
                      </v-chip>
                    </template>
                    <template v-slot:item="item">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          item.item.first_name +
                          " " +
                          (item.item.second_name
                            ? item.item.second_name + " "
                            : "") +
                          item.item.first_last_name +
                          " " +
                          item.item.second_last_name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="cancel"
              rounded
              outlined
              class="px-4"
              @click="asignPeopleDialog = false ; peopleToAsign = []"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              rounded
              class="px-4"
              @click="generateExtraFoodList(personInCharge, peopleToAsign)"
            >
              Generar Comidas
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="listAdditionalFoodModal">
      <v-dialog v-model="listAdditionalFoodModal" content-class="modalVs" width="400">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="11" class="d-flex justify-center align-center">
                <span class="text-body-1">Lista de {{ validateFoodTime(entrySelected.created_time).toLowerCase() + "s" }} adicionales</span>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="1" class="ma-0 pa-0 d-flex justify-center align-center">
                <v-btn icon color="white" title="Cerrar" @click="listAdditionalFoodModal = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
              <v-row>
                <v-col cols="12" v-for="(item, i) in entrySelected.people_list" :key="i">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title><span>{{ item.name }}</span></v-list-item-title>
                      <v-list-item-subtitle><label>{{ item.document_number }}</label></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="i < entrySelected.people_list.length - 1"></v-divider>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="totalCapacityModal">
      <v-dialog v-model="totalCapacityModal" content-class="modalVs" max-width="350px">
        <v-card>
          <v-card-title>
            <span class="text-h6">Aforo Detallado</span>
          </v-card-title>

          <v-card-text>
            <v-row v-if="campus_capacity.length > 0">
              <v-col cols="12" v-for="(item, i) in campus_capacity" :key="i" class="text-center py-0">
                <div v-for="(sede, i) in sedes" :key="i">
                  <strong v-if="sede.uuid === item.campus_id">
                    {{sede.name}}
                  </strong>
                </div>
                <div>
                  <label>
                    Entradas <strong>{{item.final_entries.length}}</strong> - Salidas <strong>{{item.final_exits.length}}</strong>
                  </label>
                </div>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="text-center">
                <label>No hay datos para mostrar</label>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="blRemoveBan">
      <v-dialog v-model="blRemoveBan" content-class="modalVs" max-width="350px" persistent>
        <v-card>
          <v-card-title>
            <span class="text-h6">Quitar Veto</span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" class="text-center">
                <p>Para retirar el veto a esta persona es necesario que seleccione el grupo de permisos que desea asignar:</p>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-select
                  v-model="removeBanNewPG"
                  :items="permissionGroupsList"
                  item-text="name"
                  item-value="uuid"
                  label="Rol"
                  outlined
                  dense
                  hide-details
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="text-center">
                <v-btn
                  color="primary"
                  :loading="loadingRemoveBan"
                  @click="removeBanToAPerson(selectedBLEvent, removeBanNewPG)"
                >
                  Retirar Veto
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" class="text-center">
                <v-btn
                  color="error"
                  @click="blRemoveBan = !blRemoveBan"
                  :disabled="loadingRemoveBan"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="blNotifyAlert">
      <v-dialog v-model="blNotifyAlert" content-class="modalVs" max-width="400px">
        <v-card>
          <v-card-title>
            <span class="text-h6">Notificar Alerta</span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" class="text-center">
                <p>Para enviar la notificacion de alerta es necesario que escriba el correo electronico del encargado:</p>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-text-field
                  v-model="notifyAlertBLPerson"
                  label="Correo Electronico"
                  outlined
                  dark
                  color="secondary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="text-center">
                <v-btn
                  class="pr-1"
                  color="primary"
                  small
                  block
                  @click="notifyBLEvent(selectedBLEvent, notifyAlertBLPerson)"
                >
                  Enviar Notificación
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" class="text-center">
                <v-btn
                  color="error"
                  block
                  small
                  @click="blNotifyAlert = !blNotifyAlert"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="blackListBox">
      <v-dialog v-model="blackListBox" content-class="modalVs" max-width="1000px">
        <v-card>
          <v-card-title>
            <span class="text-h6">Eventos Lista Negra</span>
          </v-card-title>

          <v-card-text style="background-color: #212734">
            <v-row>
              <v-col cols="12" md="4" sm="6" v-for="(item, i) in blackListEvents" :key="i">
                <v-card style="border-radius: 10px; overflow: hidden;" elevation="4" class="full-height d-flex align-center">
                  <v-card-text  class="pa-0 ma-0">
                    <v-row class="py-0 mx-0">
                      <v-col v-if="item.entry_img" cols="5" class="d-flex justify-center align-center ma-0 pa-0">
                        <div class="avatarAccess">
                          <v-img
                            :style="!lunchModule ? 'height:150px' : 'max-height:140px'"
                            :src="getDailyImg(item)"
                            lazy-src="../assets/loading.png"
                            @error="'../assets/loading.png'"
                          >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                          </v-img>
                        </div>
                      </v-col>
                      <v-col :cols="item.entry_img ? 7 : 12" :class="!item.entry_img ? 'd-flex justify-center' : ''" class="ma-0 pa-2">
                        <v-row>
                          <v-col cols="12" class="pa-0 ma-0 text-center">
                            <i style="font-size: 12px" class="fal fa-user-alt mx-1 grey--text"></i>
                            <span class="text-body-2 mb-0 text-wrap font-weight-bold">
                              <small>{{ item.name.toUpperCase() }}</small>
                            </span>
                          </v-col>
                          <v-col cols="12" class="pa-0 ma-0 text-center">
                            <i style="font-size: 12px" class="fal fa-address-card mx-1 grey--text"></i>
                            <span class="text-body-2 mb-0 text-wrap">
                              <small>{{ item.document_number }}</small>
                            </span>
                          </v-col>
                          <v-col v-if="item.entry_img" cols="12" class="pa-0 ma-0 d-flex justify-center align-center">
                            <i style="font-size: 12px" class="fal fa-building mx-2 grey--text"></i>
                            <span class="text-body-2 mb-0 text-wrap text-center">
                              <small>{{ entryDevice(item.device, item.version) && entryDevice(item.device, item.version).campus && entryDevice(item.device, item.version).campus.name ? entryDevice(item.device, item.version).campus.name : "N/E" }}</small>
                            </span>
                          </v-col>
                          <v-col v-if="item.entry_img" cols="12" class="pa-0 ma-0 d-flex justify-center align-center">
                            <i style="font-size: 12px" class="fal fa-desktop mx-2 grey--text"></i>
                            <span class="text-body-2 mb-0 text-wrap text-center">
                              <small>{{ entryDevice(item.device, item.version) && entryDevice(item.device, item.version).name ? entryDevice(item.device, item.version).name : "N/E" }}</small>
                            </span>
                          </v-col>
                          <v-col v-if="item.similarity && item.similarity != ''" cols="12" class="pa-0 ma-0 d-flex justify-center align-center">
                            <span class="text-body-2 mb-0 text-wrap text-center">
                              <small>Similaridad: {{ Math.round(item.similarity) }}%</small>
                            </span>
                          </v-col>
                          <v-col v-if="!lunchModule" cols="12" class="py-1 px-0 ma-0 d-flex justify-center align-center">
                            <v-chip x-small class="ma-0" :color="item.type_entry === 'ENTRY' ? 'secondary' : 'red'" text-color="white">
                              <small>
                                {{ item.type_entry === "ENTRY" ? "INGRESO - " + item.created_time.slice(0, 10) + " / " + item.created_time.slice(11, item.created_time.length - 8) : "SALIDA - " + item.created_time.slice(0, 10) + " / " + item.created_time.slice(11, item.created_time.length - 8) }}
                              </small>
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="py-0 mx-0">
                      <v-col cols="4" class="py-1 d-flex align-center justify-end">  
                        <label class="text-body-2 text-wrap">LN: </label>
                      </v-col>
                      <v-col cols="8" class="py-1 text-center">
                        <label v-for="(pg, i) in blList" :key="i" class="text-body-2 text-wrap font-weight-bold">
                          {{item && item.perm_group == pg.uuid ? pg.name : ''}}
                        </label>
                      </v-col>
                    </v-row>
                    <v-row class="py-0 mx-0">
                      <v-col cols="6">
                        <v-btn
                          color="secondary"
                          outlined
                          x-small
                          block
                          class="px-2"
                          @click="blRemoveBan = true; selectedBLEvent = item"
                        >
                          Retirar Veto
                        </v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn
                          color="red"
                          outlined
                          x-small
                          block
                          class="px-2"
                          @click="blNotifyAlert = true; selectedBLEvent = item"
                        >
                        Notificar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div v-if="restrictedPeopleBox">
      <v-dialog v-model="restrictedPeopleBox" content-class="modalVs" max-width="1000px">
        <v-card>
          <v-card-title style="background-color: #31394C">
            <span class="text-h6">Eventos de Personas Restringidas</span>
            <i class="fal fa-dot-circle orange--text pl-2" style="font-size: 1rem !important;"></i> 
          </v-card-title>

          <v-card-text style="background-color: #212734">
            <v-row>
              <v-col cols="12" md="4" sm="6" v-for="(item, i) in restrictedPeopleList" :key="i" @click="selectPerson(item) ; entrySelected = item ; eventDetail = true">
                <v-card style="border-radius: 10px; overflow: hidden; cursor: pointer" elevation="4" class="full-height d-flex align-center">
                  <v-card-text  class="pa-0 ma-0">
                    <v-row class="py-0 mx-0">
                      <v-col v-if="item.entry_img" cols="5" class="d-flex justify-center align-center ma-0 pa-0">
                        <div class="avatarAccess">
                          <v-img
                            :style="!lunchModule ? 'height:150px' : 'max-height:140px'"
                            :src="getDailyImg(item)"
                            lazy-src="../assets/loading.png"
                            @error="'../assets/loading.png'"
                          >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                          </v-img>
                        </div>
                      </v-col>
                      <v-col :cols="item.entry_img ? 7 : 12" :class="!item.entry_img ? 'd-flex justify-center' : ''" class="ma-0 pa-2">
                        <v-row>
                          <v-col cols="12" class="pa-0 ma-0 text-center">
                            <i style="font-size: 12px" class="fal fa-user-alt mx-1 grey--text"></i>
                            <span class="text-body-2 mb-0 text-wrap font-weight-bold">
                              <small>{{ item.name.toUpperCase() }}</small>
                            </span>
                          </v-col>
                          <v-col cols="12" class="pa-0 ma-0 text-center">
                            <i style="font-size: 12px" class="fal fa-address-card mx-1 grey--text"></i>
                            <span class="text-body-2 mb-0 text-wrap">
                              <small>{{ item.document_number }}</small>
                            </span>
                          </v-col>
                          <v-col v-if="item.entry_img" cols="12" class="pa-0 ma-0 d-flex justify-center align-center">
                            <i style="font-size: 12px" class="fal fa-building mx-2 grey--text"></i>
                            <span class="text-body-2 mb-0 text-wrap text-center">
                              <small>{{ entryDevice(item.device, item.version) && entryDevice(item.device, item.version).campus && entryDevice(item.device, item.version).campus.name ? entryDevice(item.device, item.version).campus.name : "N/E" }}</small>
                            </span>
                          </v-col>
                          <v-col v-if="item.entry_img" cols="12" class="pa-0 ma-0 d-flex justify-center align-center">
                            <i style="font-size: 12px" class="fal fa-desktop mx-2 grey--text"></i>
                            <span class="text-body-2 mb-0 text-wrap text-center">
                              <small>{{ entryDevice(item.device, item.version) && entryDevice(item.device, item.version).name ? entryDevice(item.device, item.version).name : "N/E" }}</small>
                            </span>
                          </v-col>
                          <v-col v-if="item.similarity && item.similarity != ''" cols="12" class="pa-0 ma-0 d-flex justify-center align-center">
                            <span class="text-body-2 mb-0 text-wrap text-center">
                              <small>Similaridad: {{ Math.round(item.similarity) }}%</small>
                            </span>
                          </v-col>
                          <v-col v-if="!lunchModule" cols="12" class="py-1 px-0 ma-0 d-flex justify-center align-center">
                            <v-chip x-small class="ma-0" :color="item.type_entry === 'ENTRY' ? 'secondary' : 'red'" text-color="white">
                              <small>
                                {{ item.type_entry === "ENTRY" ? "INGRESO - " + item.created_time.slice(0, 10) + " / " + item.created_time.slice(11, item.created_time.length - 8) : "SALIDA - " + item.created_time.slice(0, 10) + " / " + item.created_time.slice(11, item.created_time.length - 8) }}
                              </small>
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center" v-if="surveyModal == true">
      <v-dialog v-model="surveyModal" width="60%">
        <v-card>
          <v-overlay
            :value="overlay"
            absolute
            opacity="0.9"
          >
            <v-card color="primary" dark>
              <v-card-text>
                Generando archivo, por favor espere...
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-overlay>
          <v-card-title>
            <h5>Exportar datos de encuesta</h5>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="5">
                <!-- <label class="text-subtitle-1">Intervalo de fechas</label> -->
                <v-dialog
                  ref="dialog1"
                  v-model="rangeDateModal"
                  :return-value.sync="rangeDatesSurvey"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeTextSurvey"
                      prepend-icon="fal fa-calendar-day"
                      readonly
                      outlined
                      dense
                      height="44"
                      dark
                      class="primaryInput"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="rangeDatesSurvey"
                    no-title
                    scrollable
                    range
                    dark
                    @input="validateRangeDatesSurvey"
                    :max="maxDate"
                    locale="es-MX"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      rounded
                      large
                      outlined
                      class="px-4 mx-2 secondaryBtn"
                      @click="rangeDateModal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      rounded
                      color="primary"
                      large
                      class="px-4 mx-2 primaryBtn"
                      @click="selectDates"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="5">
                <!-- <label class="text-subtitle-1">Tipo de encuesta</label> -->
                <v-select
                  v-model="typeSurvey"
                  :items="surveyTypes"
                  item-text="title"
                  item-value="type"
                  label="Seleccione una opción"
                  outlined
                  dense
                  dark
                  color="secondary"
                  required
                  height="45"
                ></v-select>
              </v-col>
              <v-col cols="12" md="2" class="d-flex justify-center">
                <v-btn
                  color="primary"
                  large
                  @click="generateSurveyFile(typeSurvey)"
                >
                  GENERAR
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-row>
      <v-col cols="12" md="7" class="py-0 my-0">
        <v-row >
          <!-- -------------------- VISUALIZACION DEL PERFIL DEL USUARIO -------------------- -->
          <v-col cols="12">
            <v-card style="border-radius: 5px;" class="px-2">
              <!-- <v-row v-if="lastItems && lastItems.length > 0 && personSelected" class="full-height d-flex justify-center align-center px-3">
                <v-col cols="12" md="12" sm="12" class="d-flex justify-center">
                  <v-card elevation="0" width="90%">                    
                    <v-card-text class="pa-0 ma-0">
                      <v-row>
                        <v-col cols="12" md="6" class="text-center">
                          <p class="font-weight-light pb-2 secondary--text" style="font-size: 1.15rem">IMG. REGISTRO</p>
                          <div class="avatarAcces2">
                            <v-img
                              style="max-height: 250px; max-width: 600px"
                              :src="PSRegistryImg"
                              lazy-src="../assets/loading.png"
                              @error="imgErrorHandler"
                              contain
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="black"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>
                        </v-col>
                        <v-col cols="12" md="6" class="text-center">
                          <p v-if="lunchModule" class="font-weight-light pb-2 secondary--text" style="font-size: 1.15rem">IMG. ÚLTIMA ENTREGA</p>
                          <p v-if="!lunchModule" class="font-weight-light pb-2 secondary--text" style="font-size: 1.15rem">IMG. ÚLTIMO ACCESO</p>
                          <div class="avatarAcces2">
                            <v-img
                              style="max-height: 250px; max-width: 600px"
                              :src="PSDailyImg"
                              lazy-src="../assets/loading.png"
                              @error="'../assets/loading.png'"
                              contain
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="black"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                          class="text-center py-0"
                          title="DOCUMENTO"
                        >
                          <span class="text-subtitle-1">
                            <strong class="grey--text">N° Documento: </strong>
                            {{ personSelected.document_number }}
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          class="text-center py-0"
                          title="NOMBRE"
                        >
                          <span class="text-subtitle-1">
                            <strong class="grey--text">Nombre: </strong>
                            {{
                              personSelected.first_name + " " + (personSelected.second_name ? personSelected.second_name : "") + " " + personSelected.first_last_name + " " + (personSelected.second_last_name ? personSelected.second_last_name : "")
                            }}
                          </span>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                          class="text-center py-0"
                          title="TIPO SE USUARIO"
                        >
                          <span class="text-subtitle-1">
                            <strong class="grey--text">T. Usuario: </strong>
                            <div v-for="(item, i) in permissionGroupsList" :key="i">{{
                              personSelected.company_person.pin == item.uuid ? item.name : ''
                            }}</div>
                          </span>
                        </v-col>
                        <v-col cols="12" class="pa-2 ma-0 text-center">
                          <v-btn
                            color="primary"
                            rounded
                            small
                            @click="goToDetailedProfile(personSelected)"
                          >
                            Ver Perfil Detallado
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-if="lastItems && lastItems.length > 0 && !personSelected" class="full-height">
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-row>
                    <v-col class="text-center">
                      <i class="fal fa-user-circle backgroundText--text" style="font-size: 70px"></i>
                      <p class="text-subtitle-1 mt-2 backgroundText--text">
                        SELECCIONE LA PERSONA A VISUALIZAR
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="!lastItems || lastItems.length === 0" class="full-height">
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-row>
                    <v-col class="text-center">
                      <i class="fal fa-user-circle backgroundText--text" style="font-size: 70px"></i>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12"> 
                  <p class="text-center grey--text text-subtitle-1">NO PERMITIDOS</p>
                  <label v-if="nonEntries && nonEntries.length > 0 && userOrgName == 'solla'" class="text-center">
                    <strong>Sin Encuestas:</strong> {{nonPermitDataList.dbd.length}} - <strong>Restringidos:</strong> {{nonPermitDataList.d.length}} - <strong>No Reconocidos:</strong> {{nonPermitDataList.unr.length}} 
                  </label>
                  <label v-else class="text-center">
                    <strong>Restringidos:</strong> {{nonPermitDataList.d.length}} - <strong>No Reconocidos:</strong> {{nonPermitDataList.unr.length}} 
                  </label>
                </v-col>
              </v-row>
              <!-- v-if="nonPermitFilter.length > 0 || (nonEntries && nonEntries.length > 0)" -->
              <v-row class="px-2">
                <v-col cols="12" class="py-0">
                  <label>Filtro</label>
                  <v-divider></v-divider>
                </v-col>
                <v-col v-if="userOrgName == 'solla'" cols="12" md="2" class="d-flex justify-center align-center">
                  <v-checkbox
                    v-model="nonPermitFilter"
                    class="mt-0"
                    value="denied_by_day"
                    color="secondary"
                    dense
                    hide-details
                  >
                    <template v-slot:label>
                      <small>Sin Encuesta</small>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="2" class="d-flex justify-center align-center">
                  <v-checkbox
                    v-model="nonPermitFilter"
                    class="mt-0"
                    value="denied"
                    color="secondary"
                    dense
                    hide-details
                  >
                    <template v-slot:label>
                      <small>Restringido</small>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="3" class="d-flex justify-center align-center">
                  <v-checkbox
                    v-model="nonPermitFilter"
                    class="mt-0"
                    value="unrecognized"
                    color="secondary"
                    dense
                    hide-details
                  >
                    <template v-slot:label>
                      <small>No Reconocido</small>
                    </template>
                  </v-checkbox>
                </v-col>
                <v-col cols="12" :md="userOrgName == 'solla' ? 3 : 5" class="d-flex justify-center align-center">
                  <v-text-field
                    v-model="textToSearchNonPermit"
                    label="Cedula"
                    outlined
                    dark
                    hide-details="auto"
                    clear-icon="mdi-close-circle"
                    clearable
                    @click:clear="textToSearchNonPermit = ''"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="d-flex justify-center align-center">
                  <v-btn
                    class="mx-1"
                    @click="nonPermit()"
                    rounded
                    small
                    color="secondary"
                    dark
                  >
                    <i class="fal fa-search"></i>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!nonEntries || nonEntries.length == 0">
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-row>
                    <v-col class="text-center">
                      <i class="fal fa-user-slash backgroundText--text" style="font-size: 30px"></i>
                      <p class="text-body-2-1 mt-2 backgroundText--text">
                        SIN EVENTOS
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <div v-if="nonEntries && nonEntries.length > 0" style="width:100%" class="pl-2">
                <p class="text-left d-flex align-center">
                  <v-icon @click="nonEntriesPassPage('first')" :disabled="currentPage === 1">mdi-page-first</v-icon>
                  <v-icon @click="nonEntriesPassPage('prev')" :disabled="currentPage === 1">mdi-chevron-left</v-icon> 
                  {{ currentPage }} / {{ pageCount }} 
                  <v-icon @click="nonEntriesPassPage('next')" :disabled="currentPage === pageCount">mdi-chevron-right</v-icon>
                  <v-icon @click="nonEntriesPassPage('last')" :disabled="currentPage === pageCount">mdi-page-last</v-icon>
                  <span @click="currentPage = 1 ; itemsPerPage = 100; nonPermit()" :class="itemsPerPage === 100 ? '' : 'text--disabled pointer'" class="text-caption pl-2 pr-1">100</span><span class="text-caption">/</span>
                  <span @click="currentPage = 1 ; itemsPerPage = 300; nonPermit()" :class="itemsPerPage === 300 ? '' : 'text--disabled pointer'" class="text-caption px-1">300</span><span class="text-caption">/</span>
                  <span @click="currentPage = 1 ; itemsPerPage = 500; nonPermit()" :class="itemsPerPage === 500 ? '' : 'text--disabled pointer'" class="text-caption pl-1">500</span>
                </p>
              </div>
              <v-row v-if="nonEntries && nonEntries.length > 0" class="mx-0" style="overflow-y: scroll; overflow-x: hidden; max-width: 100%; max-height: 330px; margin: 0px 0px 0px 0px;">
                <v-col cols="12" md="2" v-for="(item, i) in nonEntries" :key="i">
                  <v-card class="full-height" :disabled="item.access_type != 'denied_by_day' && item.access_type != 'denied_by_time' && item.access_type != 'denied' && item.access_type != 'denied_by_company'" @click="selectNonPermit(item)">
                    <v-row class="py-0 mx-0">
                      <v-col v-if="item.entry_img" cols="12" class="d-flex justify-center ma-0 pa-0">
                        <v-img
                          contain
                          max-width="110"
                          max-height="170"
                          :src="getDailyImg(item)"
                          lazy-src="../assets/loading.png"
                          @error="'../assets/loading.png'"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="black"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col class="py-0 text-center">
                        <p v-if="item && item.name" style="font-size: 0.70rem !important;" class="text-caption grey--text py-0 my-0">
                          {{ item.name }}
                        </p>
                        <p style="font-size: 0.70rem !important;" class="text-caption grey--text py-0 my-0">
                          {{ entryDevice(item.device, item.version) && entryDevice(item.device, item.version).name ? entryDevice(item.device, item.version).name : "N/E" }}
                        </p>
                        <p v-if="item.similarity && item.similarity != '' && (item.access_type == 'denied_by_day' || item.access_type == 'denied')" style="font-size: 0.70rem !important;" class="text-caption grey--text py-0 my-0">
                          Similaridad: {{ Math.round(item.similarity) }}%
                        </p>
                        <p class="text-caption grey--text py-0 my-0">
                          <i class="fal fa-calendar-day" style="font-size: 0.70rem !important;"></i> {{ item.created_time.substring(0, 10) }}
                        </p>
                        <p class="text-caption grey--text py-0 my-0">
                          <i class="fal fa-clock" style="font-size: 0.70rem !important;"></i> {{ item.created_time.substring(11, 16) }}
                        </p>
                        <p v-if="item.access_type == 'denied' || item.access_type == 'Denied'" style="font-size: 0.70rem !important;" class="text-caption grey--text py-0 my-0">
                          <i class="fal fa-dot-circle orange--text" style="font-size: 0.70rem !important;"></i> 
                          Restringido
                        </p>
                        <p v-if="item.access_type == 'denied_by_day' || item.access_type == 'Denied_by_day'" style="font-size: 0.70rem !important;" class="text-caption grey--text py-0 my-0">
                          <i class="fal fa-dot-circle purple--text" style="font-size: 0.70rem !important;"></i>
                          Sin encuesta
                        </p>
                        <p v-if="item.access_type == 'denied_by_time' || item.access_type == 'Denied_by_time'" style="font-size: 0.70rem !important;" class="text-caption grey--text py-0 my-0">
                          <i class="fal fa-dot-circle amber--text" style="font-size: 0.70rem !important;"></i> 
                          Fuera de horario
                        </p>
                        <p v-if="item.access_type == 'unrecognized' || item.access_type == 'Unrecognized'" style="font-size: 0.70rem !important;" class="text-caption grey--text py-0 my-0">
                          <i class="fal fa-dot-circle red--text" style="font-size: 0.70rem !important;"></i> 
                          No Reconocido
                        </p>
                        <p v-if="item.access_type == 'denied_by_company'" style="font-size: 0.70rem !important;" class="text-caption grey--text py-0 my-0">
                          <i class="fal fa-dot-circle red--text" style="font-size: 0.70rem !important;"></i> 
                          No Reconocido *
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- -------------------- NUMERO DE ENTRADAS, SALIDAS Y GRAFICO DE DENCIDAD -------------------- -->
          <v-col cols="12">
            <v-card class="full-height" style="border-radius: 10px;">
              <v-row v-if="lunchModule" class="ml-10 mr-10">
                <v-col cols="12" md="4" class="text-center pa-0">
                  <v-list two-line class="backgroundPrimary">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold white--text"
                          >Desayunos
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="secondary--text pr-0 mr-0 text-h4">{{
                            foodCounters.breakfast
                          }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="4" class="text-center pa-0">
                  <v-list two-line class="backgroundPrimary">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold white--text"
                          >Almuerzos
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="secondary--text pr-0 mr-0 text-h4">{{
                            foodCounters.lunch
                          }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="4" class="text-center pa-0">
                  <v-list two-line class="backgroundPrimary">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold white--text"
                          >Cenas
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="secondary--text pr-0 mr-0 text-h4">{{
                            foodCounters.dinner
                          }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-row v-if="!lunchModule" class="ml-10 mr-10" @click="totalCapacityModal = true" style="cursor: pointer;">
                <v-col cols="12" md="6" class="text-center pa-0">
                  <v-list two-line class="backgroundPrimary">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold white--text"
                          >Accesos
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="secondary--text pr-0 mr-0 text-h4">{{
                            dailyEntries.length + socketCount
                          }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6" class="text-center pa-0">
                  <v-list two-line class="backgroundPrimary">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold white--text"
                          >Salidas
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="error--text pr-0 mr-0 text-h4">{{
                            dailyExits.length
                          }}</strong>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
              <v-row class="text-center mx-3">
                <v-col
                  cols="12"
                  class="py-0 px-2 mb-0"
                >
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="rangeDates"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        label="Intervalo de fechas"
                        prepend-icon="fal fa-calendar-day"
                        readonly
                        outlined
                        dark
                        color="secondary"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="rangeDates"
                      no-title
                      scrollable
                      range
                      locale="es-MX"
                      @input="validateRangeDates"
                      :max="maxDatePrincipal"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        color="secondary"
                        rounded
                        large
                        outlined
                        class="px-2"
                        @click="modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        color="primary"
                        large
                        class="px-2"
                        @click="datePickerAction"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>

              <v-row v-if="entries && entries.length > 0">
                <v-col cols="12" class="d-flex justify-center align-center chart-area">
                  <div style="width: 100%" class="mx-4">
                    <dash-chart
                      style="height: 250px !important ; width: 100%  !important ; position: 'relative' !important"
                      label="Ingresos"
                      :rangeDataTime="rangeDataType"
                      :chartData="{
                          shortUnitMeasure: 'Pers',
                          entries: dailyEntries,
                          exits: dailyExits,
                          color: colorHex,
                      }"
                    ></dash-chart>
                  </div>
                </v-col>
                <!-- <v-col cols="12" class="px-10" v-if="roleChecker.iHaveGrants(userRole, 'DASH-EXPORT-BTN')">
                  <v-btn
                    color="success"
                    block
                    rounded
                    large
                    :disabled="rangeDates[0] !== rangeDates[1]"
                    @click="!lunchModule ? generateReport(itemsList) : generateLunchReport(itemsList)"
                  >
                    <i class="fal fa-file-excel mx-2"></i>
                    Generar reporte
                  </v-btn>
                </v-col> -->
                <v-col cols="12" class="px-10" v-if="roleChecker.iHaveGrants(userRole, 'DASH-EXPORT-BTN')">
                  <v-btn
                    color="success"
                    block
                    rounded
                    large
                    :disabled="itemsList.length == 0"
                    @click="generateReportHistory(itemsList)"
                  >
                    <i class="fal fa-file-excel mx-2"></i>
                    Generar reporte
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="!entries || entries.length === 0">
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-row>
                    <v-col class="text-center">
                      <i class="fal fa-chart-area backgroundText--text" x-large style="font-size: 70px"></i>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <!-- <v-row class="ml-10 mr-10 text-center">
                <v-col cols="12" class="pt-0 mt-0">
                  <v-layout child-flex>
                    <v-data-table
                      :headers="headers"
                      :items="itemsList"
                      :items-per-page="5"
                      :header-props="{
                        mobile: true,
                      }"
                      :footer-props="{
                        'items-per-page-options': [5],
                      }"
                      class="elevation-1"
                      :loading="loadingReport"
                      loading-text="Cargando Información..."
                      no-data-text="No hay datos que mostrar"
                      dense
                      fixed-header
                    >
                      <template #[`item.DocumentoUsuario`]="{ item }">
                        {{ item.person.document_number }}
                      </template>
                      <template #[`item.Nombre`]="{ item }">
                        {{ item.person.first_name + " " + item.person.first_last_name + " " + item.person.second_last_name}}
                      </template>
                      <template #[`item.Evento`]="{ item }">
                        {{ item.mode === 'ENTRY' ? 'ACCESO' : 'SALIDA' }}
                      </template>
                      <template v-slot:[`item.FechaIngreso`]="{ item }">
                        {{ item.date.substring(0, 10) }}
                      </template>
                      <template #[`item.HoraIngreso`]="{ item }">
                        {{ item.time.substring(11, 16) }}
                      </template>
                    </v-data-table>
                  </v-layout>
                  <v-btn
                    color="success"
                    block
                    disabled
                    @click="generateReport(itemsList)"
                  >
                    <v-icon dark> mdi-microsoft-excel </v-icon> EXPORTAR
                    DATOS
                  </v-btn>
                </v-col>
              </v-row> -->
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- -------------------- LISTADO DE ENDRADAS Y SALIDAS -------------------- -->
      <v-col cols="12" md="5">
        <v-card class="full-height px-0 backgroundSecondary">
          <v-card-title
            v-if="!lunchModule && (lastItems && lastItems.length > 0)"
            class="d-flex justify-center grey--text text-h5 pa-0"
            >ACCESOS 
            <v-icon
              :color="changeView == true ? 'grey' : 'white'"
              class="ml-2"
              @click="changeView = !changeView"
            >
              mdi-swap-horizontal-circle-outline
            </v-icon>
            </v-card-title
          >
          <v-card-title
            v-if="lunchModule && (lastItems && lastItems.length > 0)"
            class="d-flex justify-center grey--text text-h5 pa-0"
            >ALIMENTOS ENTREGADOS</v-card-title
          >
          <v-row v-if="!lastItems || lastItems.length === 0 && loadingEntries === true" class="mx-3">
              <v-col v-for="(item, i) in 12" :key="i" cols="12" md="6" class="pa-1 d-flex justify-center">
                <v-skeleton-loader
                    class="pr-5"
                    transition="scale-transition"
                    width="400"
                    height="100"
                    type="list-item-avatar-three-line"
                ></v-skeleton-loader>
              </v-col>
          </v-row>
          <v-row v-if="!lastItems || lastItems.length === 0 && loadingEntries === false" class="full-height">
            <v-col cols="12" class="d-flex justify-center align-center">
              <v-row>
                <v-col class="text-center">
                  <v-icon
                    x-large
                    color="grey"
                    style="font-size: 70px"
                  >
                    mdi-account-multiple-outline
                  </v-icon>
                  <p class="text-h6 mt-5" style="color: #9e9e9e">AÚN NO HAY EVENTOS EL DÍA DE HOY</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row :class="lastItems.length > 15 ? 'cards-box' : ''" v-if="lastItems && lastItems.length > 0 && changeView == false">
            <v-col cols="12">
              <v-text-field
                v-model="search"
                append-icon="fal fa-search"
                label="Buscar por Cédula, Nombre o Fecha..."
                outlined
                hide-details
                color="secondary"
                dark
                class="mb-5"
                dense
              ></v-text-field>
              <v-layout child-flex>
                <v-data-table
                  dense
                  :headers="headersTest"
                  :items="entries"
                  :search="search"
                  :items-per-page="5"
                  :header-props="{
                    mobile: true,
                  }"
                  class="styleTable"
                  :footer-props="{
                    'items-per-page-options': [20],
                  }"
                  fixed-header
                  loading-text="Cargando Información..."
                  :no-results-text="`No se encontrarón ingresos por su dato de busqueda (${search})`"
                  no-data-text="No hay ingresos que mostrar"
                  @click:row="selectTableRow"
                >
                  <template v-slot:[`item.ImgAccess`]="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          size="45"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-img
                            :src="getEntryPic(item)"
                            lazy-src="../assets/loading.png"
                            @error="'../assets/loading.png'"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="black"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </template>
                      <v-img
                        :src="getEntryPic(item)"
                        width="150"
                        lazy-src="../assets/loading.png"
                        @error="'../assets/loading.png'"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="black"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.asset`]="{ item }">
                    <div v-for="(zone, j) in sedes" :key="j">
                      {{ item.asset === zone.uuid ? zone.name : "" }}
                    </div>
                  </template>
                  <template v-slot:[`item.type_entry`]="{ item }">
                    <v-chip
                      class="small-chip ma-0"
                      :color="item.type_entry === 'ENTRY' ? 'primary' : 'red'"
                      text-color="white"
                      x-small
                    >
                      {{ item.type_entry === "ENTRY" ? "Acceso" : "Salida" }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.created_time`]="{ item }" >
                    {{
                      item.created_time.substring(0, 10)
                    }} - {{
                      item.created_time.substring(11, 16)
                    }}
                  </template>
                </v-data-table>
              </v-layout>
            </v-col>
          </v-row>
          <v-row :class="lastItems.length > 15 ? 'cards-box' : ''" v-if="lastItems && lastItems.length > 0 && changeView == true">
            <v-col cols="12" class="text-right py-0">
              <span class="font-weight-light">{{lastItems.length}} / <strong class="secondary--text">{{entries.length}}</strong> </span>
            </v-col>
            <v-col
              v-for="(item, i) in lastItems"
              :key="i"
              cols="12"
              md="6"
              sm="6"
              class="pa-2"
            >
              <v-card style="border-radius: 10px; overflow: hidden;" elevation="4" class="full-height d-flex align-center pointer" @click="selectPerson(item) ; entrySelected = item ; eventDetail = true">
                <v-card-text
                  class="pa-0 ma-0"
                >
                  <v-row class="py-0 mx-0">
                    <v-col v-if="item.entry_img" cols="5" class="d-flex justify-center align-center ma-0 pa-0">
                      <div class="avatarAccess">
                        <v-img
                          :style="!lunchModule ? 'height:150px' : 'max-height:140px'"
                          :src="getDailyImg(item)"
                          @error="'../assets/loading.png'"
                          lazy-src="../assets/loading.png"
                        >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="black"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                        </v-img>
                      </div>
                    </v-col>
                    <v-col :cols="item.entry_img ? 7 : 12" :class="!item.entry_img ? 'd-flex justify-center' : ''" class="ma-0 pa-2">
                      <v-row>
                        <v-col v-if="!item.entry_img && lunchModule" cols="12" class="pa-0 ma-0 text-center">
                          <span class="text-body-2 mb-0 text-wrap">
                            AUTORIZADO POR:
                          </span>
                        </v-col>
                        <v-col cols="12" class="pa-0 ma-0 text-center">
                          <i style="font-size: 12px" class="fal fa-user-alt mx-1 grey--text"></i>
                          <span class="text-body-2 mb-0 text-wrap font-weight-bold">
                            <small>{{ item.name.toUpperCase() }}</small>
                          </span>
                        </v-col>
                        <v-col cols="12" class="pa-0 ma-0 text-center">
                          <i style="font-size: 12px" class="fal fa-address-card mx-1 grey--text"></i>
                          <span class="text-body-2 mb-0 text-wrap">
                            <small>{{ item.document_number }}</small>
                          </span>
                        </v-col>
                        <v-col v-if="item.entry_img" cols="12" class="pa-0 ma-0 d-flex justify-center align-center">
                          <i style="font-size: 12px" class="fal fa-building mx-2 grey--text"></i>
                          <span class="text-body-2 mb-0 text-wrap text-center">
                            <small>{{ entryDevice(item.device, item.version) && entryDevice(item.device, item.version).campus && entryDevice(item.device, item.version).campus.name ? entryDevice(item.device, item.version).campus.name : "N/E" }}</small>
                          </span>
                        </v-col>
                        <v-col v-if="item.entry_img" cols="12" class="pa-0 ma-0 d-flex justify-center align-center">
                          <i style="font-size: 12px" class="fal fa-desktop mx-2 grey--text"></i>
                          <span class="text-body-2 mb-0 text-wrap text-center">
                            <small>{{ entryDevice(item.device, item.version) && entryDevice(item.device, item.version).name ? entryDevice(item.device, item.version).name : "N/E" }}</small>
                          </span>
                        </v-col>
                        <v-col v-if="item.similarity && item.similarity != ''" cols="12" class="pa-0 ma-0 d-flex justify-center align-center">
                          <span class="text-body-2 mb-0 text-wrap text-center">
                            <small>Similaridad: {{ Math.round(item.similarity) }}%</small>
                          </span>
                        </v-col>
                        <v-col v-if="!lunchModule" cols="12" class="py-1 px-0 ma-0 d-flex justify-center align-center">
                          <v-chip x-small class="ma-0" :color="item.type_entry === 'ENTRY' ? 'secondary' : 'red'" text-color="white">
                            <small>
                              {{ item.type_entry === "ENTRY" ? "INGRESO - " + item.created_time.slice(0, 10) + " / " + item.created_time.slice(11, item.created_time.length - 8) : "SALIDA - " + item.created_time.slice(0, 10) + " / " + item.created_time.slice(11, item.created_time.length - 8) }}
                            </small>
                          </v-chip>
                        </v-col>
                        <v-col v-if="item.entry_img && lunchModule" cols="12" class="py-1 px-0 ma-0 d-flex justify-center align-center">
                          <v-chip x-small class="ma-0 d-flex justify-center" :color="item.type_entry === 'ENTRY' ? 'secondary' : 'red'" text-color="white" style="width:90%;">
                            <small>
                              {{ item.type_entry === "ENTRY" ? validateFoodTime(item.created_time) + " - " + item.created_time.slice(0, 10) + " / " + item.created_time.slice(11, item.created_time.length - 8) : validateFoodTime(item.created_time) + " - " + item.created_time.slice(0, 10) + " / " + item.created_time.slice(11, item.created_time.length - 8) }}
                            </small>
                          </v-chip>
                        </v-col>
                        <v-col v-if="item.entry_img && lunchModule" cols="12" class="py-1 px-0 ma-0 d-flex justify-center align-center">
                          <v-btn
                            class="primary"
                            rounded
                            x-small
                            style="width:90%;"
                            :disabled="registries.length == 0"
                            @click="openFoodModal(item)"
                          >
                            ASIGNAR <i class="fal fa-user-plus pl-1" style="font-size: 12px;"></i>
                          </v-btn>
                        </v-col>
                        <v-col v-if="!item.entry_img && item.person_in_charge && lunchModule" cols="12" class="py-1 px-0 ma-0 d-flex justify-center align-center">
                          <v-chip x-small class="ma-0" color="primary" text-color="white">
                            <small>
                              {{  item.created_time.slice(0, 10) + " / " + item.created_time.slice(11, item.created_time.length - 8) }}
                            </small>
                          </v-chip>
                        </v-col>
                        <v-col v-if="!item.entry_img && item.person_in_charge && lunchModule" cols="12" class="pa-0 ma-0 d-flex justify-center align-center">
                          <label class="text-body-2 mb-0 text-wrap text-center">
                            {{ validateFoodTime(item.created_time) + "S" }} ADICIONALES ({{item.people_list.length }}) <a @click="showList(item)" style="color: #60a5fa">Ver Lista</a>
                          </label>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="text-center pa-2 ma-0" v-if="initialGetEntryAmount < entries.length">
              <v-btn
                color="primary"
                block
                large
                rounded
                @click="loadingBtn = true ; initialGetEntryAmount = initialGetEntryAmount + 10 ; getEntries()"
                :loading="loadingBtn"
              >
                Cargar Más
              </v-btn>
            </v-col>
          </v-row>
          <v-snackbar v-model="snackbar" timeout="6000" :color="snackbarColor">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                Cerrar
              </v-btn>
            </template>
          </v-snackbar>
        </v-card>
      </v-col>
    </v-row>
    <v-fab-transition v-if="validateOrg() && roleChecker.iHaveGrants(userRole, 'SURVEY-EXPORT-BTN')">
      <v-btn
        color="success darken-1"
        large
        fab
        dark
        bottom
        right
        fixed
        @click="cleanSurveyModal() ; surveyModal = true"
      >
        <i style="font-size: 25px;" class="fal fa-poll-people"></i>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import axios from "axios";
import globals from "../globals";
import DashChart from "../components/charts/dash-chart"
import auth from "../services/auth";
import xlsx from "xlsx";
import backlog from "../services/logs";
import { v4 as uuidv4 } from "uuid";
import roles from "../services/role";

export default {
  name: "Dashboard",
  components: {
    DashChart,
  },
  data() {
    return {
      glbs: globals,
      roleChecker: roles,
      userOrgName: auth.getUserEnterpriseName(),
      userRole: auth.getRole(),
      dialog: false,
      modal: false,
      personSelected: null,
      PSDailyImg: "",
      PSRegistryImg: "",
      registerImgBackup: "",
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      company_campus: null,
      registries: [],
      lastItems: [],
      itemsList: [],
      intervall: "",
      rangeDates: [globals.COdate().substr(0, 10), globals.COdate().substr(0, 10)],
      todayDate: globals.COdate().substr(0, 10),
      colorHex: "#60A5FA",
      entries: [],
      dailyEntries: [],
      dailyExits: [],
      loadingEntries: false,
      loadingReport: false,
      loadingBtn: false,
      loadingSearch: false,
      initialGetEntryAmount: 10,
      rangeDataType: "hour",
      headers: [
        { text: "CC", value: "DocumentoUsuario", sortable: false },
        { text: "Nombre", value: "Nombre", sortable: false },
        { text: "Evento", value: "Evento", sortable: false },
        { text: "Fecha", value: "FechaIngreso", sortable: false },
        { text: "Hora", value: "HoraIngreso", sortable: false
         },
      ],
      headersNN: [
        { text: "Img. Evento", sortable: false, align: "center", value: "ImgAccess" },
        { text: "Zona", sortable: false, align: "center", value: "asset" },
        { text: "Evento", sortable: false, align: "center", value: "type_entry" },
        { text: "Fecha - Hora", sortable: true, align: "center", value: "created_time" },
      ],
      socketCount: 0,
      lunchModule: false,
      listAdditionalFoodModal: false,
      asignPeopleDialog: false,
      peopleToAsign: [],
      foodCounters: {
        breakfast: 0,
        lunch: 0, 
        dinner: 0
      },
      personInCharge: null,
      entrySelected: null,
      permissionGroupsList: [],
      nonEntries: [],
      eventDetail: false,
      nonPermitFilter: [],
      textToSearchNonPermit: "",
      itemsPerPage: 500,
      currentPage: 1,
      pageCount: 0,
      nnEntries: [],
      sedes: [],
      nonPermitDataList: {
        "dbd": [],
        "dbt": [],
        "unr": [],
        "d": []
      },
      soloEntries: [],
      last_survey: null,
      campus_capacity: [],
      totalCapacityModal: false,
      headersTest: [
        { text: "Img", sortable: false, align: "center", value: "ImgAccess" },
        { text: "CC", sortable: false, align: "center", value: "document_number" },
        { text: "Nombre", sortable: false, align: "center", value: "name" },
        { text: "Zona", sortable: false, align: "center", value: "asset" },
        { text: "Evento", sortable: false, align: "center", value: "type_entry" },
        { text: "Fecha", sortable: false, align: "center", value: "created_time" },
      ],
      search: "",
      changeView: true,
      surveyModal: false,
      surveyTypes: [
        {type: "colaborador", title: "Colaboradores"},
        {type: "tercero", title: "Terceros"}
      ],
      rangeDateModal: false,
      rangeDatesSurvey: [
        globals.COdate().substr(0, 10),
        globals.COdate().substr(0, 10),
      ],
      typeSurvey: "",
      surveyData: [],
      overlay: false,
      maxDate: "",
      maxDatePrincipal: "",
      blackListBox: false,
      blNotifyAlert: false,
      blRemoveBan: false,
      removeBanNewPG: null,
      notifyAlertBLPerson: "",
      selectedBLEvent: null,
      blackListEvents: [],
      loadingRemoveBan: false,
      blList: [],
      restrictedPeopleList: [],
      restrictedPeopleBox: false,
      nonPermitEntries: []
    };
  },
  computed: {
    dateRangeText () {
      return this.rangeDates.join(' ~ ')
    },

    dateRangeTextSurvey () {
      return this.rangeDatesSurvey.join(' ~ ')
    }
  },
  methods: {
    validateOrg() {
      if (auth.getUserEnterpriseID() == 'dea02cee-7c13-4bfd-9f6d-87b4d80efd17') {
        return true
      }
    },

    selectTableRow(r) {
      this.selectPerson(r); 
      this.entrySelected = r; 
      this.eventDetail = true;
    },

    selectDates() {
      this.$refs.dialog1.save(this.rangeDatesSurvey);
      this.rangeDateModal = false;
    },

    validateRangeDates(rangeDates) {
      if (rangeDates[0] && !rangeDates[1]) {
        let startDate = new Date(rangeDates[0])
        this.maxDatePrincipal = new Date(startDate.setDate(startDate.getDate() + 6)).toISOString().substring(0, 10);
      } else {
        if (rangeDates[0] > rangeDates[1]) {
          this.snackbar = true;
          this.snackbarText = "La fecha final no puede ser menor que la inicial."
          this.snackbarColor = "error"
          this.rangeDates = []
          this.maxDatePrincipal = ""
        }
      }
    },

    validateRangeDatesSurvey(rangeDates) {
      if (rangeDates[0] && !rangeDates[1]) {
        let startDate = new Date(rangeDates[0])
        this.maxDate = new Date(startDate.setDate(startDate.getDate() + 14)).toISOString().substring(0, 10);
      } else {
        if (rangeDates[0] > rangeDates[1]) {
          this.snackbar = true;
          this.snackbarText = "La fecha final no puede ser menor que la inicial."
          this.snackbarColor = "error"
          this.rangeDatesSurvey = []
          this.maxDate = ""
        }
      }
    },

    cleanSurveyModal() {
      this.rangeDateModal = false,
      this.rangeDatesSurvey = [
        globals.COdate().substr(0, 10),
        globals.COdate().substr(0, 10),
      ],
      this.typeSurvey = "";
      this.surveyData = [];
    },

    generateSurveyFile(typeSurvey) {
      if (this.rangeDatesSurvey[0] && this.rangeDatesSurvey[1] && typeSurvey) {
        this.overlay = true;
        axios
          .post(globals.APIURL + "forms/find-many-surveys", {
            company_id: auth.getUserEnterpriseID(),
            survey_type: typeSurvey,
            start_date: this.rangeDatesSurvey[0] + "T00:00:00.000Z",
            finish_date: this.rangeDatesSurvey[1] + "T23:59:59.000Z",
          })
          .then((res) => {
            if (res.status == 200 && !res.message) {
              if (res.data.length == 0) {
                this.snackbar = true;
                this.snackbarColor = "error"
                this.snackbarText = "No hay datos en las fechas seleccionadas"
                this.overlay = false;
              } else {
                backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "export-surveys/entries"})
                res.data.sort(function(a, b) {
                    var c = new Date(a.created_date);
                    var d = new Date(b.created_date);
                    return c-d;
                });
                this.surveyData = res.data
                this.createSurveyExportFile()
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.overlay = false;
          });
      } else {
        this.snackbar = true;
        this.snackbarColor = "error"
        this.snackbarText = "Todos los campos son obligatorios."
      }
    },

    createSurveyExportFile() {
      if (this.typeSurvey == "colaborador") {
        for (let i = 0; i < this.surveyData.length; i++) {
          const element = this.surveyData[i];
          
          delete element.company_id
          delete element.vehicle_and_driver_papers
          delete element.person_id
          delete element.vehicle_plate
          delete element.reason
          delete element.material_type
          delete element.vehicle_inspection
          delete element.company_you_work_for
          delete element.uuid
          delete element.vaccinated
          element.name = element.name.split(" ");
          element.name = element.name.map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(" ");
          element.created_date = element.created_date.substring(0, 10) + " / " + element.created_date.substring(11, 16);
          //element.vaccinated = element.vaccinated == true ? "SI" : "NO";
          element.symptoms = element.symptoms == true ? "SI" : "NO";
          if (element.mood) {
            if (Array.isArray(element.mood)) {
              element.mood = element.mood.join(' - ')
            }
          } else {
            element.mood = "N/R"
          }

          if (element.mood_reason) {
            if (Array.isArray(element.mood_reason)) {
              element.mood_reason = element.mood_reason.join(' - ')
            }
          } else {
            element.mood_reason = "N/R"
          }
        }

        let newWB = xlsx.utils.book_new();
        let newWS = xlsx.utils.json_to_sheet(this.surveyData, {
          header: [
            "document_number",
            "name",
            "created_date",
            "place_access",
            "cellphone",
            "email",
            "work_type",
            "survey_type",
            "role",
            "mood",
            "mood_reason",
            //"vaccinated",
            "symptoms",
          ],
        });

        newWS["A1"].v = "NÚMERO DOCUMENTO";
        newWS["B1"].v = "NOMBRE";
        newWS["C1"].v = "FECHA / HORA";
        newWS["D1"].v = "REGIONAL";
        newWS["E1"].v = "CELULAR";
        newWS["F1"].v = "CORREO";
        newWS["G1"].v = "LUGAR DE TRABAJO";
        newWS["H1"].v = "TIPO ENCUESTA";
        newWS["I1"].v = "TIPO COLABORADOR";
        newWS["J1"].v = "ESTADO DE ÁNIMO";
        newWS["K1"].v = "RAZÓN ESTADO DE ANIMO";
        //newWS["L1"].v = "ESQUEMA COMPLETO";
        newWS["L1"].v = "ALGÚN SÍNTOMA";

        xlsx.utils.book_append_sheet(newWB, newWS, "Encuestas");

        xlsx.writeFile(
          newWB,
          "VT - Reporte encuestas de salud " + this.rangeDatesSurvey[0] + " a " + this.rangeDatesSurvey[1] + ".xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );

        this.overlay = false;
        this.snackbar = true
        this.snackbarText = "Se generó el archivo!!"
        this.snackbarColor  = "success"
        backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "export/survey"})
        this.cleanSurveyModal()
      }
    },

    generateReportHistory(itemsList) {
      if (itemsList.length > 0) {
        //this.entryListReport = this.entryListReport.filter(value => value.document_number !== "")

        for (let i = 0; i < this.itemsList.length; i++) {
          const element = this.itemsList[i];

          element.DocumentNumber = element.document_number
          element.FullName = element.name
          element.type_entry = element.type_entry == 'ENTRY' ? "ENTRADA" : "SALIDA"
          element.EntryDate = element.created_time.substring(0, 10)
          element.EntryTime = element.created_time.substring(11, 19)
          this.sedes.forEach(e => {// **NEW
            if (e.uuid == element.asset) {
              element.asset = e.name
            }
          });
          delete element.person_id
          delete element.device
          delete element.uuid
          delete element.type_person
          delete element.register_image
          delete element.document_number
          delete element.name
          delete element.created_time
          delete element.entry_img
          delete element.similarity
          delete element.slave_mac
        }

        let newWB = xlsx.utils.book_new();
        let newWS = xlsx.utils.json_to_sheet(itemsList, {
          header: [
            "DocumentNumber",
            "FullName",
            "asset",
            "type_entry",
            "EntryDate",
            "EntryTime"
          ],
        });

        newWS["A1"].v = "NRO. DOCUMENTO";
        newWS["B1"].v = "NOMBRE";
        newWS["C1"].v = "ZONA";
        newWS["D1"].v = "TIPO";
        newWS["E1"].v = "FECHA";
        newWS["F1"].v = "HORA ACCESO";

        xlsx.utils.book_append_sheet(newWB, newWS, "Ingresos");

        xlsx.writeFile(
          newWB,
          "VT - Ingresos & Salidas - " + auth.getGZ().name + " " + this.rangeDates[0] + " a " + this.rangeDates[1] + ".xlsx",
          {
            type: "file",
            bookType: "xlsx",
          }
        );
        
        this.snackbarText = "Se descargó su reporte de ingresos.";
        this.snackbarColor = "success";
        this.snackbar = true;
        backlog.generateLog({"enterprise": auth.getUserEnterpriseName(), "username": auth.getUsername(), "role": auth.getRole(), "action": "export/entries"})
        this.nonPermit()
        this.getEntries()
        this.getEntriesByDates()
        this.maxDatePrincipal = ""
      }
    },
    // **********************************************************

    getEntryPic(item) {
      var formatURL = "";
      if (item.document_number !== "") {
        formatURL = globals.CDNURL + item.entry_img;
      }
      return formatURL;
    },

    customFilter(item, queryText) {
      const textAllName =
        (item.first_name ? item.first_name.toLowerCase() : "") +
        " " +
        (item.second_name ? item.second_name.toLowerCase() : "") +
        " " +
        (item.first_last_name ? item.first_last_name.toLowerCase() : "") +
        " " +
        (item.second_last_name ? item.second_last_name.toLowerCase() : "");
      const textNameAndFirstLastname =
        (item.first_name ? item.first_name.toLowerCase() : "") +
        " " +
        (item.first_last_name ? item.first_last_name.toLowerCase() : "") +
        " " +
        (item.second_last_name ? item.second_last_name.toLowerCase() : "");
      const textNameAndAllLastname =
        (item.first_name ? item.first_name.toLowerCase() : "") +
        " " +
        (item.first_last_name ? item.first_last_name.toLowerCase() : "");
      const cedula = item.document_number ? item.document_number : "";
      const searchText = queryText.toLowerCase();

      return (
        textAllName.indexOf(searchText) > -1 ||
        textNameAndFirstLastname.indexOf(searchText) > -1 ||
        textNameAndAllLastname.indexOf(searchText) > -1 ||
        cedula.indexOf(searchText) > -1
      );
    },

    validateFoodTime(hour) {
      hour = hour.slice(11, 16)
      if (hour > "00:00" && hour < "12:00") {
        return "DESAYUNO";
      } else if (hour > "12:00" && hour < "18:00") {
        return "ALMUERZO";
      } else {
        return "CENA";
      }
    },

    showList(item) {
      this.listAdditionalFoodModal = true;
      this.entrySelected = item;
    },

    openFoodModal(person) {
      this.asignPeopleDialog = true
      this.personInCharge = this.asignPerson(person.document_number);
    },

    asignPerson(cedula) {
      for (let i = 0; i < this.registries.length; i++) {
        const element = this.registries[i];
        if (element.document_number == cedula) {
          return element;
        }
      }
    },

    selectNonPermit (item) {
      if (item.access_type == 'denied' || item.access_type == 'denied_by_day' || item.access_type == 'denied_by_time' || item.access_type == 'denied_by_company') {
        this.selectPerson(item); 
      }
    },

    generateExtraFoodList(personInCharge, peopleWhoReceive) {
      if (peopleWhoReceive.length > 0) {
        let listOfPeople = []

        for (let i = 0; i < peopleWhoReceive.length; i++) {
          const element = peopleWhoReceive[i];

          listOfPeople.push({
            name: element.first_name + " " + element.first_last_name,
            document_number: element.document_number,
            person_id: element.uuid
          })
        }

        let body = {
          type_device: "restaurant",
          company_id: auth.getUserEnterpriseID(),
          entry_data: {
            uuid: uuidv4(),
            person_id: personInCharge.uuid,
            document_number: personInCharge.document_number,
            name: personInCharge.first_name + " " + personInCharge.first_last_name,
            type_person: "",
            asset: auth.getSelectedSede(),
            created_time: globals.COdate(),
            device: "",
            type_entry: "ENTRY",
            entry_img: "",
            register_image: "",
            people_list: listOfPeople,
            person_in_charge: true
          }
        }

        axios({
          method: "POST",
          url: "https://tob9omblt1.execute-api.us-east-1.amazonaws.com/Prod/access/create-entry",
          data: JSON.stringify(body),
        })
        .then((res) => {
          console.log(res);
        });

        this.asignPeopleDialog = false;
        this.personInCharge = null;
        this.peopleToAsign = [];
        this.snackbar = true
        this.snackbarColor = "success";
        this.snackbarText = "Se generaron las comidas correctamente."
        this.getEntries();
        this.getEntriesByDates()
      } else {
        this.snackbar = true
        this.snackbarColor = "error";
        this.snackbarText = "Es necesario que seleccione al menos una persona."
      }
    },

    generateLunchReport(list) {
      let inSiteList = [];
      let awayList = [];

      list.map((entry => {
        if (entry.person_in_charge) {
          entry.people_list.map((person) => {
            awayList.push({
              personInChargeName: entry.name,
              personInChargeDocument: entry.document_number,
              nameWhoReceive: person.name,
              documentWhoReceive: person.document_number,
              foodName: this.validateFoodTime(entry.created_time),
              created_time: entry.created_time.substring(0, 10) + "  " + entry.created_time.substring(11, 16),
            })
          })
        } else {
          inSiteList.push({
            name: entry.name,
            document_number: entry.document_number,
            foodName: this.validateFoodTime(entry.created_time),
            created_time: entry.created_time.substring(0, 10) + "  " + entry.created_time.substring(11, 16),
          })
        }
      }))

      let newWB = xlsx.utils.book_new();

      let newWS1 = xlsx.utils.json_to_sheet(inSiteList, {
        header: [
          "name",
          "document_number",
          "foodName",
          "created_time"
        ],
      });

      let newWS = xlsx.utils.json_to_sheet(awayList, {
        header: [
          "personInChargeName",
          "personInChargeDocument",
          "nameWhoReceive",
          "documentWhoReceive",
          "foodName",
          "created_time",
        ],
      });

      newWS1["A1"].v = "NOMBRE EMPLEADO";
      newWS1["B1"].v = "CC EMPLEADO";
      newWS1["C1"].v = "TIPO ALIMENTO";
      newWS1["D1"].v = "FECHA - HORA";

      newWS["A1"].v = "NOMBRE QUIEN AUTORIZA";
      newWS["B1"].v = "CC QUIEN AUTORIZA";
      newWS["C1"].v = "NOMBRE QUIEN RECIBE";
      newWS["D1"].v = "CC QUIEN RECIBE";
      newWS["E1"].v = "TIPO ALIMENTO";
      newWS["F1"].v = "FECHA - HORA";

      xlsx.utils.book_append_sheet(newWB, newWS1, "En sitio");
      xlsx.utils.book_append_sheet(newWB, newWS, "Para llevar");
      

      xlsx.writeFile(
        newWB,
        "Reporte de Alimentos " + this.rangeDates[0] + ".xlsx",
        {
          type: "file",
          bookType: "xlsx",
        }
      );

      this.snackbarText = "Se genero el reporte con exito.";
      this.snackbarColor = "success";
      this.snackbar = true;
    },

    imgErrorHandler(url) {
      console.log("Handler de imagen de registro", url)
      this.PSRegistryImg = this.registerImgBackup//"https://847395.smushcdn.com/1790738/wp-content/uploads/2015/09/imagen-no-encontrada.jpg?lossy=0&strip=1&webp=1"
      this.$forceUpdate()
    },

    datePickerAction() {
      this.$refs.dialog.save(this.rangeDates)
      this.modal = false
      //this.getEntriesToExport()
      this.loadingSearch = true
      this.loadingEntries = true
      this.lastItems = []
      this.entries = [];
      this.dailyEntries = [];
      this.dailyExits = [];
      this.itemsList = [];
      this.nonEntries = [];
      this.nonPermit()
      this.getEntries()
      this.getEntriesByDates()
      this.maxDatePrincipal = ""
    },

    getDailyEntries() {
      axios
        .post(globals.APIURL + "entry/fbd", {
          campus_id: auth.getSelectedSede(),
          company_id: auth.getUserEnterpriseID(),
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          amount: this.initialGetEntryAmount
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.lastItems = res.data
            this.loadingEntries = false
            this.loadingBtn = false
            this.loadingSearch = false
          }
          this.loadingEntries = false
          this.loadingBtn = false
          this.loadingSearch = false
        })
        .catch((err) => {
          this.loadingEntries = false
          this.loadingBtn = false
          this.loadingSearch = false
          if (err.response.data == "No documents were found") {
            console.log("No hay documentos que mostrar!");
          } else {
            console.log(err);
          }
        });
    },

    getByDates() {
      axios
        .post(globals.APIURL + "entry/fabd", {
          campus_id: auth.getSelectedSede(),
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z"
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            var entryCounter = [];
            var exitCounter = [];
            res.data.forEach((element) => {
              if (element.mode === 'ENTRY') {
                entryCounter.push(element);
              } else {
                exitCounter.push(element);
              }
            });
            this.entries = res.data;
            this.itemsList = res.data;
            (this.rangeDates[0] === this.rangeDates[1]) ? this.rangeDataType = "hour" : this.rangeDataType = "day"
            this.dailyEntries = entryCounter;
            this.dailyExits = exitCounter;
          }
        })
        .catch((err) => {
          if (err.response.data == "No documents were found") {
            console.log("No hay documentos que mostrar!");
          } else {
            console.log(err);
          }
        });
    },

    getEntriesToExport() {
      if (this.rangeDates[1] >= this.rangeDates[0]) {
        this.loadingSearch = true
        this.loadingEntries = true
        this.lastItems = []
        this.entries = [];
        this.dailyEntries = [];
        this.dailyExits = [];
        this.itemsList = [];
        axios
          .post(globals.APIURL + "entry/fbd", {
            campus_id: auth.getSelectedSede(),
            start_date: this.rangeDates[0] + "T00:00:00.000Z",
            finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          })
          .then((res) => {
            if (res.data && res.data.length > 0) {
              this.itemsList = res.data;
              this.getByDates()
              this.getDailyEntries()
            }
          })
          .catch((err) => {
            this.loadingSearch = false
            console.log(err);
          });
      } else {
        this.snackbarText = "La fecha final no puede ser menor a la inicial.";
        this.snackbarColor = "red";
        this.snackbar = true;
        this.rangeDates[1] = "";
      }
    },

    getRegistry() {
      /* axios
        .post(globals.APIURL + "registry/fasl", {
          company_id: auth.getUserEnterpriseID(),
          campus_id: auth.getSelectedSede()
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.registries = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        }); */
        axios// **NEW
        .post(globals.APIURL + "registry/all", {
          company_id: auth.getUserEnterpriseID()
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.registries = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    generateReport(jsonContent) {
      // TODO: ORGANIZAR LOGICA PARA REPORTE DE AUSENCIAS.
      // ** Logica para organizar datos de reporte de accesos y salidas
      let inAndOutpersons = []

      jsonContent.forEach((element) => {
          let inAndOutPersonIndex = inAndOutpersons.findIndex(item => item.person_id === element.person_id)

          if (inAndOutPersonIndex === -1) {
            inAndOutpersons.push(element)
          } else if (inAndOutPersonIndex >= 0 && element.type_entry === "EXIT") {
            inAndOutpersons[inAndOutPersonIndex].time_exit = element.created_time
          } 
      });
      
      // ** Logica para generar las aucensias
      inAndOutpersons.forEach((el) => {
          let registryIndex = this.registries.findIndex(item => item.uuid === el.person_id)
          if (registryIndex >= 1) {
            this.registries.splice(registryIndex, 1 );
          }
      });

      inAndOutpersons.forEach(element => {
        element.DocumentoUsuario = element.document_number;
        element.Nombre = element.name
        element.Fecha = element.created_time.substring(0, 10)
        element.HoraEntrada = (element.created_time) ? element.created_time.substring(11, 16) : "N/R"
        element.HoraSalida = (element.time_exit) ? element.time_exit.substring(11, 16) : "N/R"
        // TODO: Reorganizar logica para mostrar este dato //element.HorasTrabajadas = (element.created_time && element.time_exit) ? parseInt(element.time_exit.substring(11, 13)) - parseInt(element.created_time.substring(11, 13)) : "NO SE PUEDE CALCULAR"
        /* delete element.uuid;
        delete element.campus_id;
        delete element.date;
        delete element.person_id;
        delete element.time;
        delete element.person;
        delete element.campus;
        delete element.temp;
        delete element.mode;
        delete element.image;
        delete element.created_at;
        delete element.device_id;
        delete element.device;
        delete element.vehicles; */
        delete element.time_exit;
        delete element.person_id
        delete element.type_entry
        delete element.device
        delete element.uuid
        delete element.type_person
        delete element.register_image
        delete element.document_number
        delete element.name
        delete element.created_time
        delete element.asset
        delete element.entry_img
      });

      this.registries.forEach(element => {
        element.regDocumentoUsuario = element.document_number;
        element.regNombre = element.first_last_name + " " + element.second_last_name + " " + element.first_name
        delete element.uuid
        delete element.email
        delete element.first_name
        delete element.second_name
        delete element.first_last_name
        delete element.second_last_name
        delete element.phone
        delete element.birthdate
        delete element.rh
        delete element.gender
        delete element.RekognitionId
        delete element.document_type
        delete element.document_number
        delete element.updated_at
        delete element.created_at
        delete element.company_person
        delete element.vehicles
        delete element.register_image
      });


      // ** Formato para generacion de excel
      let newWB = xlsx.utils.book_new();
      if (inAndOutpersons.length > 0) {
        let newWS = xlsx.utils.json_to_sheet(inAndOutpersons, {
          header: [
            "DocumentoUsuario",
            "Nombre",
            "Fecha",
            "HoraEntrada",
            "HoraSalida",
            //"HorasTrabajadas", // TODO: Reorganizar logica para mostrar este dato
          ],
        });

        newWS["A1"].v = "CC";
        newWS["B1"].v = "NOMBRE";
        newWS["C1"].v = "FECHA";
        newWS["D1"].v = "HORA ACCESO";
        newWS["E1"].v = "HORA SALIDA";
        //newWS["F1"].v = "HORAS LABORADAS"; // TODO: Reorganizar logica para mostrar este dato

        xlsx.utils.book_append_sheet(newWB, newWS, "Ingresos & Salidas");
      }
      
      if (this.registries.length > 0) {
        let newWS2 = xlsx.utils.json_to_sheet(this.registries, {
          header: [
            "regDocumentoUsuario",
            "regNombre"
          ],
        });

        newWS2["A1"].v = "CC";
        newWS2["B1"].v = "NOMBRE";

        xlsx.utils.book_append_sheet(newWB, newWS2, "Ausencias");
      }

      xlsx.writeFile(
        newWB,
        "Reporte VS-" + this.rangeDates[0] + ".xlsx",
        {
          type: "file",
          bookType: "xlsx",
        }
      );

      this.snackbarText = "Se descargó su reporte de ingresos.";
      this.snackbarColor = "success";
      this.snackbar = true;
      backlog.generateLog({
        enterprise: auth.getUserEnterpriseName(),
        username: auth.getUsername(),
        role: auth.getRoleName(),
        action: "export/entries",
      });
    },

    selectPerson(item) {
      this.personSelected = null
      this.entrySelected = null
      this.PSRegistryImg = ""
      this.registerImgBackup = ""
      this.PSDailyImg = ""
      this.soloEntries = []
      this.last_survey = null
      if (item && this.asignPeopleDialog == false && this.listAdditionalFoodModal == false) {
        axios
          .post(globals.APIURL + "registry/fo", {
            person_id: item.person_id ? item.person_id : null,
            company_id: auth.getUserEnterpriseID(),
            document_number: item.document_number ? item.document_number : null,
            access_type: item.access_type
          })
          .then((res) => {
            if (res.data.uuid) {
              this.personSelected = res.data
              this.entrySelected = item;
              this.eventDetail = true;
              this.PSRegistryImg = ""
              this.registerImgBackup = ""
              if (item.access_type == 'denied_by_company') {
                this.getEventsOfNN(res.data)
              } else if (!item.access_type || item.access_type == "denied_by_day" || item.access_type == "denied" || (item.access_type !== 'denied_by_company' && item.access_type !== 'unrecognized')) {
                this.getEventsSoloEvents(res.data)
                this.getSurvey(item.document_number)
              }
              let enterpriseSplited = auth.getUserEnterpriseName().split(" ");
              this.PSDailyImg = globals.CDNURL + item.entry_img;
              if (res.data.register_image) {
                this.PSRegistryImg = globals.APIURL + "entry/registry-pic/" + res.data.register_image;
              } else {
                console.log(globals.APIURL + "entry/entry-picture/" + enterpriseSplited[0] + "/" + res.data.first_name + "/" + res.data.first_last_name + "/" + item.document_number)
                this.PSRegistryImg = globals.APIURL + "entry/entry-picture/" + enterpriseSplited[0] + "/" + res.data.first_name + "/" + res.data.first_last_name + "/" + item.document_number;
              }

              if (res.data.entries.length > 0) {
                this.registerImgBackup = globals.CDNURL + res.data.entries[0].image[0];
              } 

            }
          })
          .catch((err) => {
            this.loadingReg = false
            this.loadingProfile = false
            console.log(err.response);
          });
      }
    },

    getEventsOfNN(person) {
      axios
        .post(globals.APIURL + "entry/get-nn-entries", {
          person_id: person.uuid,
          ids: auth.getGZ().zones
        })
        .then((res) => {
          if (res.data && res.data.length > 0 && res.data != 'No documents were found') {
            this.nnEntries = res.data
          } else {
            this.nnEntries = []
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getEventsSoloEvents(person) {
      axios
        .post(globals.APIURL + "entry/get-solo-entries", {
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          ids: auth.getGZ().zones,
          person_id: person.document_number
        })
        .then((res) => {
          if (res.data && res.data.length > 0 && res.data != 'No documents were found') {
            this.soloEntries = res.data
          } else {
            this.soloEntries = []
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getSurvey(document_number) {
      this.last_survey = null;
      axios({
        method: "POST",
        url: globals.APIURL + "forms/find-last-surveys",
        data: {
          company_id: auth.getUserEnterpriseID(),
          document_number: document_number,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.length > 0) {
            this.last_survey = res.data[res.data.length - 1]
          }
        }
      }).catch(err => {
        console.log(err)
      });
    },

    getDailyImg(item) {
      var formatURL = "";
      if (item) {
        formatURL = globals.CDNURL + item.entry_img
      } 
      return formatURL;
    },

    getRegistryImg(item) {
      let formatURL = "";
      if (item) {
        formatURL =
          globals.APIURL +
          "entry/entry-picture/" +
          auth.getUserEnterpriseName() +
          "/" +
          item.person.first_name +
          "/" +
          item.person.first_last_name +
          "/" +
          item.person.document_number;
      }
      return formatURL;
    },


    goToDetailedProfile(personSelected) {
      this.$router.replace({ name: 'registrations', params: { cedula_registro: { uuid: personSelected.uuid, document_number: personSelected.document_number } } });
    },

    capitalizeName(name) {
      return name.charAt(0) + name.slice(1).toLowerCase();
    },

    validateLunchDash() {
      axios
        .post(globals.APIURL + "device/fb", { 
          filter: {
            campus_id: auth.getSelectedSede() 
          }
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];

              if (element.type_device == "restaurant") {
                this.lunchModule = true;
              }
              
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // TODO: Desde aqui empieza nueva logica Access 2.0
    entryDevice(device, version) {
      if (this.devices && this.devices.length > 0) {
        for (let i = 0; i < this.devices.length; i++) {
          const element = this.devices[i];
          if (version === "v2") {
            if (device === element.uuid) {
              return element;
            }
          } else {
            if (device === element.mac) {
              return element;
            }
          }
        }
      }
    },

    getDevices() {
      axios
        .post(globals.APIURL + "device/get-devices-grouped", {// **NEW
          ids: auth.getGZ().zones
        })
        .then(data =>{ 
          if (data.data.length > 0) {
            this.devices = data.data
            this.getRegistry();
            this.getEntries();
            this.getEntriesByDates();
          } else {
            this.getRegistry();
            this.getEntries();
            this.getEntriesByDates();
          }
        })
        .catch(err => {
          this.getRegistry();
          this.getEntries();
          this.getEntriesByDates();
          console.log(err);
        })
      /* axios
        .post(globals.APIURL + "device/fb", {
          filter: {
            campus_id: auth.getSelectedSede()
          }
        })
        .then(data =>{ 
          if (data.data.length > 0) {
            this.devices = data.data
            this.getRegistry();
            this.getEntries();
            this.getEntriesByDates();
          } else {
            this.getRegistry();
            this.getEntries();
            this.getEntriesByDates();
          }
        })
        .catch(err => {
          this.getRegistry();
          this.getEntries();
          this.getEntriesByDates();
          console.log(err);
        }) */
      /* axios
        .post("https://ikdw6qev45.execute-api.us-east-1.amazonaws.com/Dev/devices", {
          asset_key: auth.getSelectedSede()
        })
        .then(data =>{ 
          if (data.data.length > 0) {
            this.devices = data.data
            this.getRegistry();
            this.getEntries();
            this.getEntriesByDates();
          } else {
            this.getRegistry();
            this.getEntries();
            this.getEntriesByDates();
          }
        })
        .catch(err => {
          this.getRegistry();
          this.getEntries();
          this.getEntriesByDates();
          console.log(err);
        }) */
    },

    getEntries(r) {
      /* axios({
        method: "POST",
        url: "https://ikdw6qev45.execute-api.us-east-1.amazonaws.com/Dev/entries",
        data: {
          asset_key: auth.getSelectedSede(),
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          limit: this.initialGetEntryAmount
        },
      })
      .then((res) => {
        console.log("Lista de 10 nueva: ", res)
        if (res.data && res.data.length > 0) {
          this.lastItems = res.data
          this.loadingEntries = false
          this.loadingBtn = false
          this.loadingSearch = false
        }
        this.loadingEntries = false
        this.loadingBtn = false
        this.loadingSearch = false
      })
      .catch((err) => {
        console.log(err)
        //this.loadingEntries = false
        //this.loadingBtn = false
        //this.loadingSearch = false
        //if (err.response.data == "No documents were found") {
        //  console.log("No hay documentos que mostrar!");
        //} else {
        //  console.log(err);
        //}
      }); */
      axios({// **NEW
        method: "POST",
        url: globals.APIURL + "entry/grouped-entries",
        data: {
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          limit: 20000,
          ids: auth.getGZ().zones
        },
      })
      .then((res) => {
        if (res.data && res.data.length > 0 && res.data != 'No documents were found') {
          this.lastItems = res.data.slice(0, this.initialGetEntryAmount)
          if(!r || r !== 'reload') {
            this.loadingEntries = false
            this.loadingBtn = false
            this.loadingSearch = false
          }
        }
        this.loadingEntries = false
        this.loadingBtn = false
        this.loadingSearch = false
      })
      .catch((err) => {
        console.log(err)
        this.loadingEntries = false
        //this.loadingBtn = false
        //this.loadingSearch = false
        //if (err.response.data == "No documents were found") {
        //  console.log("No hay documentos que mostrar!");
        //} else {
        //  console.log(err);
        //}
      });
    },

    getEntriesByDates() {
      this.foodCounters.breakfast = 0;
      this.foodCounters.lunch = 0;
      this.foodCounters.dinner = 0;
      /* axios({
        method: "POST",
        url: "https://ikdw6qev45.execute-api.us-east-1.amazonaws.com/Dev/entries",
        data: {
          asset_key: auth.getSelectedSede(),
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          limit: null
        },
      })
      .then((res) => {
        if (res.data && res.data.length > 0) {
          var entryCounter = [];
          var exitCounter = [];
          res.data.forEach((element) => {
            if (this.lunchModule) {
              let foodTime = this.validateFoodTime(element.created_time);

              if (foodTime == "DESAYUNO") {
                if (element.person_in_charge) {
                  this.foodCounters.breakfast += element.people_list.length; 
                } else {
                  this.foodCounters.breakfast += 1; 
                }
              } else if (foodTime == "ALMUERZO") {
                if (element.person_in_charge) {
                  this.foodCounters.lunch += element.people_list.length;
                } else {
                  this.foodCounters.lunch += 1;
                }
              } else {
                if (element.person_in_charge) {
                  this.foodCounters.dinner += element.people_list.length;
                } else {
                  this.foodCounters.dinner += 1;
                }
              }
            }

            if (element.type_entry === 'ENTRY') {
              entryCounter.push(element);
            } else {
              exitCounter.push(element);
            }
          });
          this.entries = res.data;
          this.itemsList = res.data;
          (this.rangeDates[0] === this.rangeDates[1]) ? this.rangeDataType = "hour" : this.rangeDataType = "day"
          this.dailyEntries = entryCounter;
          this.dailyExits = exitCounter;
        }
      })
      .catch((err) => {
        if (err.response.data == "No documents were found") {
          console.log("No hay documentos que mostrar!");
        } else {
          console.log(err);
        }
      }); */
      axios({// **NEW
        method: "POST",
        url: globals.APIURL + "entry/grouped-entries",
        data: {
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          limit: 20000,
          ids: auth.getGZ().zones
        },
      })
      .then((res) => {
        if (res.data && res.data.length > 0 && res.data != 'No documents were found') {
          var entryCounter = [];
          var exitCounter = [];
          res.data.forEach((element) => {
            if (this.lunchModule) {
              let foodTime = this.validateFoodTime(element.created_time);

              if (foodTime == "DESAYUNO") {
                if (element.person_in_charge) {
                  this.foodCounters.breakfast += element.people_list.length; 
                } else {
                  this.foodCounters.breakfast += 1; 
                }
              } else if (foodTime == "ALMUERZO") {
                if (element.person_in_charge) {
                  this.foodCounters.lunch += element.people_list.length;
                } else {
                  this.foodCounters.lunch += 1;
                }
              } else {
                if (element.person_in_charge) {
                  this.foodCounters.dinner += element.people_list.length;
                } else {
                  this.foodCounters.dinner += 1;
                }
              }
            }

            if (element.type_entry === 'ENTRY') {
              entryCounter.push(element);
            } else {
              exitCounter.push(element);
            }
          });
          this.entries = res.data;
          this.itemsList = res.data;
          (this.rangeDates[0] === this.rangeDates[1]) ? this.rangeDataType = "hour" : this.rangeDataType = "day"
          /* this.dailyEntries = entryCounter;
          this.dailyExits = exitCounter; */
          this.entryExitCalculate(entryCounter.reverse(), exitCounter)
        }
      })
      .catch((err) => {
        if (err.response.data == "No documents were found") {
          console.log("No hay documentos que mostrar!");
        } else {
          console.log(err);
        }
      });
    },

    entryExitCalculate(entries, exits) {
      let places = {}
      this.campus_capacity = []

      if (entries.length > 0) {
        entries.forEach(element => {
          if (!this.dailyEntries.some(org => org.document_number === element.document_number)) {
            this.dailyEntries.push(element)
          }
        });
      }

      if (exits.length > 0) {
        exits.forEach(element => {
          if (!this.dailyExits.some(org => org.document_number === element.document_number)) {
            this.dailyExits.push(element)
          }
        });
      }

      if (entries.length > 0 || exits.length > 0) {
        for (let i = 0; i < auth.getGZ().zones.length; i++) {
          const el = auth.getGZ().zones[i];

          entries.forEach(element => {
            if (element.asset == el) {
              if (!places[el]) {
                places[el] = {
                  campus_id: el,
                  entries: [],
                  exits: []
                }
              }

              places[el].entries.push(element)
            }
          });

          exits.forEach(element2 => {
            if (element2.asset == el) {
              if (!places[el]) {
                places[el] = {
                  campus_id: el,
                  entries: [],
                  exits: []
                }
              }

              places[el].exits.push(element2)
            }
          });
        }

        Object.entries(places).forEach(element => {
          this.campus_capacity.push(element["1"])
        });

        for (let y = 0; y < this.campus_capacity.length; y++) {
          const campus = this.campus_capacity[y];
          
          if(!campus.final_entries) campus.final_entries = [];
          if(!campus.final_exits) campus.final_exits = [];
          
          campus.entries.forEach(e => {
            if (!campus.final_entries.some(org => org.document_number === e.document_number)) {
              campus.final_entries.push(e)
            }
          });

          campus.exits.forEach(e => {
            if (!campus.final_exits.some(org => org.document_number === e.document_number)) {
              campus.final_exits.push(e)
            }
          });
        }
      }
    },

    getPremissionGroups() {// **NEW
      axios({
        method: "GET",
        baseURL: globals.APIURL + "permission-group/get-pg/" + auth.get_asset_id(),
      }).then((data) => {
        if (data.data.length > 0) {
          for (let i = 0; i < data.data.length; i++) {
            const element = data.data[i];
            
            if (element.type_group == 'black_list') {
              this.blList.push(element)
              data.data.splice(i, 1)
            }
          }
          this.permissionGroupsList = data.data
        }
      }).catch((err) => {
        console.log(err)
      });
    },

    buildWSConnections() {// **NEW
      for (let i = 0; i < auth.getGZ().zones.length; i++) {
        this["WSConnection" + i] = null
      }
    },

    closeWSConnections() {// **NEW
      if (auth.getGZ() && auth.getGZ().zones.length > 0) {
        for (let i = 0; i < auth.getGZ().zones.length; i++) {
          this["WSConnection" + i].close()
        }
      }
    },

    nonPermit() {
      axios({
        method: "POST",
        url: globals.APIURL + "entry/grouped-non-entries",
        data: {
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          limit: 20000,
          ids: auth.getGZ().zones,
          types: this.nonPermitFilter,
          document_number: this.textToSearchNonPermit
        },
      })
      .then((res) => {
        if (res.data && res.data.length > 0 && res.data != 'No documents were found') {
          this.nonPermitEntries = res.data
          this.nonPermitCalculate(this.nonPermitEntries)

          this.pageCount = Math.ceil(this.nonPermitEntries.length / this.itemsPerPage)
          const startItemPage = (this.currentPage - 1) * this.itemsPerPage
          const endItemPage = startItemPage + this.itemsPerPage
          this.nonEntries = this.nonPermitEntries.slice(startItemPage, endItemPage)
        } else {
          this.nonEntries = []
        }
      })
      .catch((err) => {
        if (err.response.data == "No documents were found") {
          console.log("No hay documentos que mostrar!");
        } else {
          console.log(err);
        }
      });
    },

    nonEntriesPassPage(mode) {
      if (mode === 'next') {
        this.currentPage++
      }

      if (mode === 'prev') {
        this.currentPage--
      }

      if (mode === 'first') {
        this.currentPage = 1
      }
      
      if (mode === 'last') {
        this.currentPage = this.pageCount
      }

      this.pageCount = Math.ceil(this.nonPermitEntries.length / this.itemsPerPage)
      const startItemPage = (this.currentPage - 1) * this.itemsPerPage
      const endItemPage = startItemPage + this.itemsPerPage
      this.nonEntries = this.nonPermitEntries.slice(startItemPage, endItemPage)
    },

    nonPermitCalculate(nonPermitEntries) {
      let singlePersonList = []
      let dbd = []
      let dbt = []
      let d = []
      let unr = []
      if (nonPermitEntries.length > 0) {
        nonPermitEntries.forEach(element => {
          if (element.person_id != 'nul') {
            if (!singlePersonList.some(o => o.person_id === element.person_id)) {
              singlePersonList.push(element)
            }
          } else {
            singlePersonList.push(element)
          }
        });
      }

      singlePersonList.forEach((element) => {
        if (element.access_type === 'denied_by_day') {
          dbd.push(element);
        }
        if (element.access_type === 'denied_by_time') {
          dbt.push(element);
        }
        if (element.access_type === 'denied') {
          d.push(element);
        }
        if (element.access_type === 'unrecognized' || element.access_type === 'denied_by_company') {
          unr.push(element);
        }
      });

      this.nonPermitDataList = {dbd, dbt, d, unr};
    },

    // * Gets events every 10 seconds yoinc
    callEventsEvery() {
      this.intervall = window.setInterval(
        function () {
          this.nonPermit();
          this.getBlackListEvents("init")
          this.getEntries("reload")
          this.getEntriesByDates()
          if (this.$store.getters.restrictedPopupStatus) {
            this.restrictedPeople()
          }
        }.bind(this),
        10000
      );
    },

    getSedes() {
      this.sedes = []
      axios
        .post(globals.APIURL + "org/fo", {
          company_id: auth.getUserEnterpriseID(),
        })
        .then((res) => {
          if (res.data && res.data.Campus.length > 0) {
            this.sedes = res.data.Campus;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // * Black List

    getBlackListEvents(r) {
      axios({
        method: "post",
        url: globals.APIURL + "entry/get-bl-entries",
        data: {
          start_date: this.rangeDates[0] + "T00:00:00.000Z",
          finish_date: this.rangeDates[1] + "T23:59:59.000Z",
          limit: 10000,
          ids: auth.getGZ().zones
        },
      })
      .then((res) => {
        if (res.data && res.data.length > 0 && res.data != 'No documents were found') {
          this.blackListEvents = res.data
          this.$store.commit('asignBlackListNotifications', res.data.length);
          (r && r == 'init') ? this.blackListBox = false : this.blackListBox = true;
        } else {
          this.$store.commit('asignBlackListNotifications', 0)
        }
      })
      .catch((err) => {
        if (err.response.data == "No documents were found") {
          console.log("No hay documentos que mostrar!");
          this.$store.commit('asignBlackListNotifications', 0)
        } else {
          console.log(err);
        }
      });
    },

    // TODO: ASIGN ENPOINT TO RESOLVE
    notifyBLEvent(item, personToNotify) {
      axios
        .post(globals.APIURL + "entry/notify-from-bl", {
          company_id: auth.getUserEnterpriseID(),
          campus_id: auth.getSelectedSede(),
          person_to_notify: personToNotify,
          event_data: item
        })
        .then((res) => {
        if (res && res.status == 200) {
          this.getBlackListEvents("init")
          this.snackbar = true
          this.snackbarColor = "success"
          this.snackbarText = "Se notificó la persona encargada"
          this.blNotifyAlert = false;
          this.notifyAlertBLPerson = "";
          this.selectedBLEvent = null;
        }
      });
    },

    // TODO: ASIGN ENPOINT TO RESOLVE
    removeBanToAPerson(item, newPG) {
      this.loadingRemoveBan = true
      axios
        .post(globals.APIURL + "registry/remove-from-bl", {
          company_id: auth.getUserEnterpriseID(),
          campus_id: auth.getSelectedSede(),
          person_id: item.person_id,
          campus: newPG.places,
          pin: newPG && newPG.places.length > 0 ? newPG.uuid : item.pin,
          ids: auth.getGZ().zones,
          start_date: globals.COdate().substring(0, 10) + "T00:00:00.000Z",
          finish_date: globals.COdate().substring(0, 10) + "T23:59:59.000Z"
        })
        .then((res) => {
        if (res && res.status == 200) {
          if (res.data == "Person removed correctly") {
            console.log(res.data)
            this.getBlackListEvents("init")
            this.loadingRemoveBan = false
            this.snackbar = true
            this.snackbarColor = "success"
            this.snackbarText = "Se removio el ban a esta persona"
            this.blRemoveBan = false;
            this.removeBanNewPG = null;
            this.selectedBLEvent = null;
          }
        }
      });
    },

    restrictedPeople() {
      axios({
        method: "POST",
        url: globals.APIURL + "entry/grouped-non-entries",
        data: {
          start_date: globals.COdate().substring(0, 10) + "T00:00:00.000Z",
          finish_date: globals.COdate().substring(0, 10) + "T23:59:59.000Z",
          limit: 2000,
          ids: auth.getGZ().zones,
          types: ["denied"],
          document_number: ""
        },
      })
      .then((res) => {
        if (res.data && res.data.length > 0 && res.data != 'No documents were found') {
          this.restrictedPeopleList = res.data
          this.$store.commit('asignRestrictedPeopleNotifications', res.data.length);
          if (res.data.length > this.$store.getters.restrictedPeopleNotifications) {
            this.restrictedPeopleBox = true 
          }
        } else {
          this.$store.commit('asignRestrictedPeopleNotifications', 0)
        }
      })
      .catch((err) => {
        if (err.response.data == "No documents were found") {
          console.log("No hay documentos que mostrar!");
          this.$store.commit('asignRestrictedPeopleNotifications', 0)
        } else {
          console.log(err);
        }
      });
    },

  },
  created() {
    //-----------------------
    //websocket
    //-----------------------
    // let that = this;
    // // **NEW
    // that.buildWSConnections()
    // for (let i = 0; i < auth.getGZ().zones.length; i++) {
    //    
    //   this["WSConnection" + i] = new WebSocket(
    //     "wss://nadeomank9.execute-api.us-east-1.amazonaws.com/dev?userid=" +
    //       auth.getUsername() +
    //       "&assetkey=" +
    //       auth.getGZ().zones[i]
    //   );
    //
    //   this["WSConnection" + i].onopen = function () {
    //     //console.log("Successfully connected to the echo websocket server...");
    //   };
    //
    //   this["WSConnection" + i].onmessage = function (event) {
    //     if (event.data) {
    //       let evt = JSON.parse(event.data);
    //       if (evt.asset == auth.getGZ().zones[i]) {
    //         if (!evt.access_type) {
    //           that.getEntries();
    //           that.getEntriesByDates();
    //         } else {
    //           that.nonPermit()
    //         }
    //       }
    //     }
    //   };
    // }
    /* this.WSConnection = new WebSocket(
      "wss://nadeomank9.execute-api.us-east-1.amazonaws.com/dev?userid=" +
        auth.getUsername() +
        "&assetkey=" +
        auth.getSelectedSede()
    );

    this.WSConnection.onopen = function (event) {
      console.log(event);
      console.log("Successfully connected to the echo websocket server...");
    };

    this.WSConnection.onmessage = function (event) {
      if (event.data) {
        let evt = JSON.parse(event.data);
        if (auth.getGZ().zones.includes(evt.asset)) {//evt.asset == auth.getSelectedSede()) {
          that.getEntries();
          that.getEntriesByDates();
        }
        //that.entries.push(evt)
        //that.itemsList.push(evt)
        //if (evt.type_entry == "ENTRY") {
        //  that.dailyEntries.push(evt);
        //} else {
        //  that.dailyExits.push(evt);
        //}
        //that.lastItems.splice(that.lastItems.length - 1, 1);
        //that.lastItems.splice(0, 0, evt);
      }
    }; */
  },
  mounted() {
    this.validateLunchDash()
    this.getPremissionGroups()
    this.loadingEntries = true
    this.getDevices();
    this.nonPermit()
    this.getSedes()
    if (this.$store.getters.restrictedPopupStatus) {
      this.restrictedPeople()
    }
    this.getBlackListEvents('init');
    this.callEventsEvery()
    this.$bus.$on("popupsBlackList", () => {
      this.getBlackListEvents();
    });
    this.$bus.$on("popupRestrictedPeople", () => {
      this.restrictedPeople();
      this.restrictedPeopleBox = true
    });
  },
  beforeDestroy() {
    clearInterval(this.intervall);
    //this.WSConnection.close();
    //this.closeWSConnections()
  },
};
</script>

<style>
.chart-area {
  height: 100%;
  width: 100%;
}

.full-height {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.card-body-success {
  border-style: groove;
  border-width: 0px 3px 3px 3px;
  border-color: #8bc34a;
}

.card-body-danger {
  border-style: groove;
  border-width: 5px 5px 5px 5px;
  border-color: #f44336;
}

.brightness {
  filter: brightness(1.2);
}
.cards-box {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1b7; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.styleTable {
  background-color: #31394c !important;
  color: rgb(255 255 255 / 87%) !important;
}
.styleTable tbody tr:nth-child(odd) {
  background-color: #212734 !important;
}
.styleTable > .v-data-table__wrapper > table > thead > tr > th {
  color: #60A5FA !important;
}
.styleTable tbody .v-data-table__empty-wrapper {
  color: #fff !important;
}
.styleTable table thead tr th {
  background: #ffffff14 !important;
}
.styleTable table tbody tr td {
  /* font-size: 0.6rem !important; */
  font-size: 0.8rem !important;
  height: 48px !important;
}
</style>